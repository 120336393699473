
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>Country</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" >
              <div class="row">
                <div class="col-lg-6"> 
                    
                      <div class="form-group row">
                        <label for="name" class="col-lg-3 col-form-label">Name</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="name" [(ngModel)]="obj.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                          <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="iso" class="col-lg-3 col-form-label">ISO </label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="iso" [(ngModel)]="obj.iso" #iso="ngModel" [ngClass]="{ 'is-invalid': f.submitted && iso.invalid }" required />
                          <div *ngIf="f.submitted && iso.invalid" class="invalid-feedback">
                            <div *ngIf="iso.errors.required">ISO is required</div>
                          </div>
                        </div>
                      </div>
 
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>                     
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/setup/country']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>  
  </form> 
</div>
