<!-- Wrapper-->
<div id="wrapper">
  <!-- Left navigation bar -->
  <nav-bar></nav-bar>
  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg" style="padding:0">
    <!-- Top navigation -->
    <top-bar></top-bar>    
    <div class="wrapper wrapper-content">   
      <router-outlet></router-outlet>  
    </div>    
    <!-- Footer -->
    <footer-bar></footer-bar>
  </div>
 
</div> 
<div class="progress-loader" *ngIf="loading">
  <div class="loader"></div>
</div>
<!-- End wrapper-->


