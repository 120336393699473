import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { DataService } from './services/dataService';
import { LoaderService } from './services/services.loading';
import { AlertService } from './services/AlertService';
 
@NgModule({
  declarations: [
  
  ],
  imports: [
    CommonModule
    , RouterModule
    , FormsModule
  ],
  exports: [
     FormsModule
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
    },DataService, AuthService,LoaderService,AlertService
  ],
})

export class SharedModule { }
  
