<form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="modal-header" >
      <h4 class="modal-title">Bank Account</h4>
      <button type="button" class="close" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label for="bankAccountTypeId" class="col-lg-4 col-form-label">ประเภท</label>
        <div class="col-lg-8">
          <ng-select  [options]="bankAccountTypeList"   name="bankAccountTypeId" [(ngModel)]="obj.bankAccountTypeId" #bankAccountTypeId="ngModel"   [ngClass]="{ 'is-invalid': f.submitted && bankAccountTypeId.invalid }" required ></ng-select>
          <div *ngIf="f.submitted && bankAccountTypeId.invalid" class="invalid-feedback">
            <div *ngIf="bankAccountTypeId.errors.required">Field is required</div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="bankId" class="col-lg-4 col-form-label">ธนาคาร</label>
        <div class="col-lg-8">
          <ng-select  [options]="bankList"   name="bankId" [(ngModel)]="obj.bankId" #bankId="ngModel"   [ngClass]="{ 'is-invalid': f.submitted && bankId.invalid }" required ></ng-select>
          <div *ngIf="f.submitted && bankId.invalid" class="invalid-feedback">
            <div *ngIf="bankId.errors.required">Field is required</div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="branchName" class="col-lg-4 col-form-label">สาขา</label>
        <div class="col-lg-8">
          <input type="text" class="form-control" name="branchName" [(ngModel)]="obj.branchName" #branchName="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && branchName.invalid }" required  />        
          <div *ngIf="f.submitted && branchName.invalid" class="invalid-feedback">
            <div *ngIf="branchName.errors.required">Field is required</div>
          </div>             
        </div>
      </div>
      <div class="form-group row">
        <label for="accountNo" class="col-lg-4 col-form-label">เลขบัญชี</label>
        <div class="col-lg-8">
          <input type="text" class="form-control" name="accountNo" [(ngModel)]="obj.accountNo" #accountNo="ngModel" [ngClass]="{ 'is-invalid': f.submitted && accountNo.invalid }" required  />     
          <div *ngIf="f.submitted && accountNo.invalid" class="invalid-feedback">
            <div *ngIf="accountNo.errors.required">Field is required</div>
          </div>                 
        </div>
      </div>
      <div class="form-group row">
        <label for="accountName" class="col-lg-4 col-form-label">ชื่อบัญชี</label>
        <div class="col-lg-8">
          <input type="text" class="form-control" name="accountName" [(ngModel)]="obj.accountName" #accountName="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && accountName.invalid }" required />    
          <div *ngIf="f.submitted && bankId.invalid" class="invalid-feedback">
            <div *ngIf="accountName.errors.required">Field is required</div>
          </div>                  
        </div>
      </div>
      <div class="form-group row">
        <label for="accountName" class="col-lg-4 col-form-label">isATS</label>
        <div class="col-lg-8">
          <label class="ui-checkbox ui-checkbox-primary">
            <input type="checkbox" [(ngModel)]="obj.isATS" [ngModelOptions]="{standalone: true}" [checked]="obj.isATS" /> <span class="input-span"></span>
          </label>                
        </div>
      </div>

      <div class="form-group row">
        <label for="contactRoad" class="col-lg-4 col-form-label">isDefault</label>
        <div class="col-lg-8">
          
          <label class="ui-checkbox ui-checkbox-primary">
            <input type="checkbox" [(ngModel)]="obj.isDefault" [ngModelOptions]="{standalone: true}" [checked]="obj.isDefault" /> <span class="input-span"></span>
          </label>            
        </div>
      </div>
      <div class="form-group row">
        <label for="contactRoad" class="col-lg-4 col-form-label">สถานะ</label>
        <div class="col-lg-8">
          <label class="switch"><input name="active"   [(ngModel)]="obj.active"  type="checkbox"  [checked]="obj.active === 1"><span class="slider round"></span></label>           
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary mr-10"  >Submit</button>
      <button type="button" class="btn btn-secondary" (click)="hide()">Cancel</button>
    </div>   
  </form>