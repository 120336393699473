import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
 
 
import { Router, ActivatedRoute } from "@angular/router";
 
declare var jQuery:any;

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.template.html'
})

export class DashboardComponent implements OnDestroy, OnInit {

  public nav:any;
  public todos: any;
  routeParams = null;
  obj: any = {};
  menuList = [];
  subMenuList = [];
  roleslist = [];
  permission: any = {};

  public constructor(
   
    private router: Router,
 ) {
    //this.nav = document.querySelector('nav.navbar');

  }

  async ngOnInit(): Promise<void> {
 
    //this.getSubMenu();
  }


  public ngOnDestroy():any {
    //this.nav.classList.remove("white-bg");
  }
 
 
}
