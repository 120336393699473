
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>เมนูหลัก</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content"  >
              <div class="row">
                <div class="col-lg-6">
                 
                    <div class="form-group row">
                      <label for="code" class="col-lg-3 col-form-label">Code</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="code" [(ngModel)]="obj.code" #code="ngModel" [ngClass]="{ 'is-invalid': f.submitted && code.invalid }" required />
                        <div *ngIf="f.submitted && code.invalid" class="invalid-feedback">
                          <div *ngIf="code.errors.required">Code is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-lg-3 col-form-label">Name</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="name" [(ngModel)]="obj.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                          <div *ngIf="name.errors.required">Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-lg-3 col-form-label">Description</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="description" [(ngModel)]="obj.description" #description="ngModel"  />
                        
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="icon" class="col-lg-3 col-form-label">Icon</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="icon" [(ngModel)]="obj.icon" #icon="ngModel" [ngClass]="{ 'is-invalid': f.submitted && icon.invalid }" required />
                        <div *ngIf="f.submitted && icon.invalid" class="invalid-feedback">
                          <div *ngIf="icon.errors.required">Icon is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="seq" class="col-lg-3 col-form-label">Seq</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="seq" [(ngModel)]="obj.seq" #seq="ngModel" [ngClass]="{ 'is-invalid': f.submitted && seq.invalid }" required />
                        <div *ngIf="f.submitted && seq.invalid" class="invalid-feedback">
                          <div *ngIf="seq.errors.required">Seq is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="status" class="col-lg-3 col-form-label">Status</label>
                      <div class="col-lg-9">                        
                        <label class="switch"><input name="status"   #status="ngModel" [(ngModel)]="obj.status"  type="checkbox"  [checked]="obj.status === 1"><span class="slider round"></span></label>
                      </div>
                    </div>
                  
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>                     
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/admin/mainmenu']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>   
  </form> 
</div>
