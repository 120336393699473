
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>Investor</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" >
              <div class="row">
                <div class="col-md-6">
                 
                  <div class="form-group row">
                    <label for="seq" class="col-lg-4 col-form-label">ประเภท <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.personTypeList"   name="personTypeId" [(ngModel)]="obj.personTypeId" #personTypeId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && personTypeId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && personTypeId.invalid" class="invalid-feedback">
                        <div *ngIf="personTypeId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="seq" class="col-lg-4 col-form-label">คำนำหน้า <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.titleList"   name="titleId" [(ngModel)]="obj.titleId" #titleId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && titleId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && titleId.invalid" class="invalid-feedback">
                        <div *ngIf="titleId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="firstName" class="col-lg-4 col-form-label">ชื่อ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="firstName" [(ngModel)]="obj.firstName" #firstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required />
                      <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                        <div *ngIf="firstName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="lastName" class="col-lg-4 col-form-label">นามสกุล <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="lastName" [(ngModel)]="obj.lastName" #lastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required />
                      <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                        <div *ngIf="lastName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                
                
              
  
                </div>
                <div class="col-md-6">
          

                  <div class="form-group row">
                    <label for="cardNumber" class="col-lg-4 col-form-label">เลขประจำตัวผู้เสียภาษี <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="cardNumber" [(ngModel)]="obj.cardNumber" #cardNumber="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cardNumber.invalid }" required />
                      <div *ngIf="f.submitted && cardNumber.invalid" class="invalid-feedback">
                        <div *ngIf="cardNumber.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="cardIssueDate" class="col-lg-4 col-form-label">วันเริ่มอายุบัตร </label>
                    <div class="col-lg-8">
                      <ng-datepicker   name="cardIssueDate" [(ngModel)]="obj.cardIssueDate" #cardIssueDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cardIssueDate.invalid }" required ></ng-datepicker>
                      <div *ngIf="f.submitted && cardIssueDate.invalid" class="invalid-feedback">
                        <div *ngIf="cardIssueDate.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="cardExpireDate" class="col-lg-4 col-form-label">วันหมดอายุบัตร </label>
                    <div class="col-lg-8">
                      <ng-datepicker   name="cardExpireDate" [(ngModel)]="obj.cardExpireDate" #cardExpireDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cardExpireDate.invalid }" required ></ng-datepicker>
                      <div *ngIf="f.submitted && cardExpireDate.invalid" class="invalid-feedback">
                        <div *ngIf="cardExpireDate.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="licenseNo" class="col-lg-4 col-form-label">รหัส IC <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="licenseNo" [(ngModel)]="obj.licenseNo" #licenseNo="ngModel" [ngClass]="{ 'is-invalid': f.submitted && licenseNo.invalid }" required/>
                      <div *ngIf="f.submitted && licenseNo.invalid" class="invalid-feedback">
                        <div *ngIf="licenseNo.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
              <h4 class="text-primary">ข้อมูลติดต่อ</h4>
              <hr>
              <div class="row">
                <div class="col-md-6">                 
             
                  <div class="form-group row">
                    <label for="mobile" class="col-lg-4 col-form-label">มือถือ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mobile" [(ngModel)]="obj.mobile" #mobile="ngModel" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required />
                      <div *ngIf="f.submitted && mobile.invalid" class="invalid-feedback">
                        <div *ngIf="mobile.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phone" class="col-lg-4 col-form-label">เบอร์บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="phone" [(ngModel)]="obj.phone" #phone="ngModel" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="email" class="col-lg-4 col-form-label">อีเมล</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="email" [(ngModel)]="obj.email" #email="ngModel"   />                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="fax" class="col-lg-4 col-form-label">แฟกซ์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="fax" [(ngModel)]="obj.fax" #fax="ngModel"    />
                   
                    </div>
                  </div>
                </div>  
              </div> 
              
              <h4 class="text-primary">ที่อยู่ที่ติดต่อ</h4>
              <hr>
              <div class="row" >
                <div class="col-md-6">                 
                  <div class="form-group row">
                    <label for="addr" class="col-lg-4 col-form-label">เลขที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="addr" [(ngModel)]="obj.addr" #addr="ngModel" [ngClass]="{ 'is-invalid': f.submitted && addr.invalid }" required  />
                      <div *ngIf="f.submitted && addr.invalid" class="invalid-feedback">
                        <div *ngIf="addr.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="moo" class="col-lg-4 col-form-label">หมู่ที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="moo" [(ngModel)]="obj.moo" #moo="ngModel"  />
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="building" class="col-lg-4 col-form-label">อาคาร/หมู่บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="building" [(ngModel)]="obj.building" #building="ngModel" />
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="floor" class="col-lg-4 col-form-label">ซอย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="floor" [(ngModel)]="obj.floor" #floor="ngModel"   />                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="road" class="col-lg-4 col-form-label">ถนน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="road" [(ngModel)]="obj.road" #road="ngModel"  />                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="provinceId" class="col-lg-4 col-form-label">จังหวัด</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.provinceList"   name="provinceId" [(ngModel)]="obj.provinceId" #provinceId="ngModel" (ngModelChange)="changeProvince($event)" [ngClass]="{ 'is-invalid': f.submitted && provinceId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && provinceId.invalid" class="invalid-feedback">
                        <div *ngIf="provinceId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="districtId" class="col-lg-4 col-form-label">เขต/อำเภอ</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.districtList"   name="districtId" [(ngModel)]="obj.districtId" #districtId="ngModel"  (ngModelChange)="changeDistrict($event)" [ngClass]="{ 'is-invalid': f.submitted && districtId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && districtId.invalid" class="invalid-feedback">
                        <div *ngIf="districtId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="subDistrictId" class="col-lg-4 col-form-label">แขวง/ตำบล</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.subDistrictList"   name="subDistrictId" [(ngModel)]="obj.subDistrictId" #subDistrictId="ngModel"   (ngModelChange)="changeSubDistrict($event)" [ngClass]="{ 'is-invalid': f.submitted && subDistrictId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && subDistrictId.invalid" class="invalid-feedback">
                        <div *ngIf="subDistrictId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="zipCode" class="col-lg-4 col-form-label">รหัสไปรษณีย์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="zipCode" [(ngModel)]="obj.zipCode" #zipCode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && zipCode.invalid }" required readonly  />
                      <div *ngIf="f.submitted && zipCode.invalid" class="invalid-feedback">
                        <div *ngIf="zipCode.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>                     
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/setup/investor']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>  
  </form> 
</div>
