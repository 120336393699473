
<div class="wrapper container wrapper-content animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox ">
        <div class="ibox-title">
          <ul id="myTab" role="tablist" class="nav nav-tabs nav-pills flex-column flex-sm-row text-center  border-0 rounded-nav">
            <li class="nav-item  ">
              <a   data-toggle="tab" href="#home" role="tab" class="nav-link border-0 text-uppercase font-weight-bold active">ข้อมูลส่วนตัว</a>
            </li>
            <li class="nav-item ">
              <a  data-toggle="tab" href="#profile" role="tab"   class="nav-link border-0 text-uppercase font-weight-bold">ข้อมูลติดต่อ</a>
            </li>
            <li class="nav-item ">
              <a  data-toggle="tab" href="#contact" role="tab"  class="nav-link border-0 text-uppercase font-weight-bold">ข้อมูลทางการเงิน</a>
            </li>
            <!--<li class="nav-item ">
              <a  data-toggle="tab" href="#benefit" role="tab"  class="nav-link border-0 text-uppercase font-weight-bold">ผู้เกี่ยวข้อง <span class="badge badge-danger">2</span></a>
            </li>-->
            <li class="nav-item ">
              <a  data-toggle="tab" href="#bank" role="tab"  class="nav-link border-0 text-uppercase font-weight-bold">บัญชีธนาคาร <span class="badge badge-danger" *ngIf="data.bankaccountList && data.bankaccountList.length>0">{{data.bankaccountList.length}}</span></a>
            </li>
            <li class="nav-item ">
              <a  data-toggle="tab" href="#files" role="tab"  class="nav-link border-0 text-uppercase font-weight-bold">เอกสารแนบ <span class="badge badge-danger" *ngIf="data.attachmentList && data.attachmentList.length>0">{{data.attachmentList.length}}</span></a>
            </li>
            <li class="nav-item ">
              <a  data-toggle="tab" href="#other" role="tab"  class="nav-link border-0 text-uppercase font-weight-bold">ข้อมูลอื่น</a>
            </li>
         
          </ul>
            
        </div>
        <div class="ibox-content" >
         
          <div id="myTabContent" class="tab-content">
            <div id="home" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade px-4   active show">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="seq" class="col-lg-4 col-form-label">ประเภท <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.accountTypeList" [optionValue]="'name'" [multiple]="true" name="accountType" [(ngModel)]="obj.accountType" #accountType="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && accountType.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && accountType.invalid" class="invalid-feedback">
                        <div *ngIf="accountType.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="seq" class="col-lg-4 col-form-label">APP ID </label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="applicationId" [(ngModel)]="obj.applicationId" #applicationId="ngModel" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="seq" class="col-lg-4 col-form-label">คำนำหน้า <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.titleList"   name="titleId" [(ngModel)]="obj.titleId" #titleId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && titleId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && titleId.invalid" class="invalid-feedback">
                        <div *ngIf="titleId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="firstName" class="col-lg-4 col-form-label">ชื่อ (ไทย) <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="firstName" [(ngModel)]="obj.firstName" #firstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required />
                      <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                        <div *ngIf="firstName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="lastName" class="col-lg-4 col-form-label">นามสกุล (ไทย) <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="lastName" [(ngModel)]="obj.lastName" #lastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required />
                      <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                        <div *ngIf="lastName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="firstNameEng" class="col-lg-4 col-form-label">ชื่อ (อังกฤษ) <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="firstNameEng" [(ngModel)]="obj.firstNameEng" #firstNameEng="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstNameEng.invalid }" required />
                      <div *ngIf="f.submitted && firstNameEng.invalid" class="invalid-feedback">
                        <div *ngIf="firstNameEng.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="lastNameEng" class="col-lg-4 col-form-label">นามสกุล (อังกฤษ) <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="lastNameEng" [(ngModel)]="obj.lastNameEng" #lastNameEng="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastNameEng.invalid }" required />
                      <div *ngIf="f.submitted && lastNameEng.invalid" class="invalid-feedback">
                        <div *ngIf="lastNameEng.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  
  
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="seq" class="col-lg-4 col-form-label">ผู้แนะนำการลงทุน</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.investorContactList" [optionLabel]="'fullName'"  name="investorContactId" [(ngModel)]="obj.investorContactId" #investorContactId="ngModel"  ></ng-select>
                      
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="birthDate" class="col-lg-4 col-form-label">SubmittedTime </label>
                    <div class="col-lg-8">
                      <ng-datepicker  [(ngModel)]="obj.submittedTime" name="SubmittedTime" :disabled="true"   #submittedTime="ngModel" readonly > </ng-datepicker>
                     
                    </div>                     
                  </div>



                  <div class="form-group row">
                    <label for="birthDate" class="col-lg-4 col-form-label">วันเดือนปีเกิด <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-datepicker  [(ngModel)]="obj.birthDate" name="birthDate"    #birthDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && birthDate.invalid }" required > </ng-datepicker>
                      <div *ngIf="f.submitted && birthDate.invalid" class="invalid-feedback">
                        <div *ngIf="birthDate.errors.required">Field is required</div>
                      </div>
                    </div>                     
                  </div>

            
              

                  <div class="form-group row">
                    <label for="cardTypeId" class="col-lg-4 col-form-label">ประเภทบัตร <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.cardTypeList"   name="cardTypeId" [(ngModel)]="obj.cardTypeId" #cardTypeId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && cardTypeId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && cardTypeId.invalid" class="invalid-feedback">
                        <div *ngIf="cardTypeId.errors.required">Field is required</div>
                      </div>
                     
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="cardNumber" class="col-lg-4 col-form-label">เลขที่บัตร <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="cardNumber" [(ngModel)]="obj.cardNumber" #cardNumber="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cardNumber.invalid }" required />
                      <div *ngIf="f.submitted && cardNumber.invalid" class="invalid-feedback">
                        <div *ngIf="cardNumber.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="cardIssueDate" class="col-lg-4 col-form-label">วันเริ่มอายุบัตร </label>
                    <div class="col-lg-8">
                      <ng-datepicker   name="cardIssueDate" [(ngModel)]="obj.cardIssueDate" #cardIssueDate="ngModel" ></ng-datepicker>
                    
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="cardExpireDate" class="col-lg-4 col-form-label">วันหมดอายุบัตร </label>
                    <div class="col-lg-8">
                      <ng-datepicker   name="cardExpireDate" [(ngModel)]="obj.cardExpireDate" #cardExpireDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && cardExpireDate.invalid }" required ></ng-datepicker>
                      <div *ngIf="f.submitted && cardExpireDate.invalid" class="invalid-feedback">
                        <div *ngIf="cardExpireDate.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="countryId" class="col-lg-4 col-form-label">สัญชาติ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.countryList"   name="countryId" [(ngModel)]="obj.countryId" #countryId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && countryId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && countryId.invalid" class="invalid-feedback">
                        <div *ngIf="countryId.errors.required">Field is required</div>
                      </div>
                     
                    </div>
                  </div>
                  
                  
                  <div class="form-group row">
                    <label for="maritalStatusId" class="col-lg-4 col-form-label">สถานะภาพ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.maritalStatusList"   name="maritalStatusId" [(ngModel)]="obj.maritalStatusId" #maritalStatusId="ngModel"  (ngModelChange)="changeMaritalstatus($event)" [ngClass]="{ 'is-invalid': f.submitted && maritalStatusId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && maritalStatusId.invalid " class="invalid-feedback">
                        <div *ngIf="maritalStatusId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="hasSpouse">
                  <h3>ข้อมูลคู่สมรส</h3>
                  <hr>
                  <div class="form-group row">
                    <label for="spouseThFirstName" class="col-lg-4 col-form-label">ชื่อคู่สมรส ไทย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="spouseThFirstName" [(ngModel)]="obj.spouseThFirstName" #spouseThFirstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && spouseThFirstName.invalid }" required  />
                      <div *ngIf="f.submitted && spouseThFirstName.invalid &&hasSpouse" class="invalid-feedback">
                        <div *ngIf="spouseThFirstName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="spouseThLastName" class="col-lg-4 col-form-label">นามสกุลคู่สมรส ไทย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="spouseThLastName" [(ngModel)]="obj.spouseThLastName" #spouseThLastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && spouseThLastName.invalid }" required  />
                      <div *ngIf="f.submitted && spouseThLastName.invalid &&hasSpouse" class="invalid-feedback">
                        <div *ngIf="spouseThLastName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="spouseEnFirstName" class="col-lg-4 col-form-label">ชื่อคู่สมรส Eng</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="spouseEnFirstName" [(ngModel)]="obj.spouseEnFirstName" #spouseEnFirstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && spouseEnFirstName.invalid }" required  />
                      <div *ngIf="f.submitted && spouseEnFirstName.invalid &&hasSpouse" class="invalid-feedback">
                        <div *ngIf="spouseEnFirstName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="spouseEnLastName" class="col-lg-4 col-form-label">นามสกุลคู่สมรส Eng</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="spouseEnLastName" [(ngModel)]="obj.spouseEnLastName" #spouseEnLastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && spouseEnLastName.invalid }" required  />
                      <div *ngIf="f.submitted && spouseEnLastName.invalid &&hasSpouse" class="invalid-feedback">
                        <div *ngIf="spouseEnLastName.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="spouseCardNumber" class="col-lg-4 col-form-label">เลขบัตรประชาชน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="spouseCardNumber" [(ngModel)]="obj.spouseCardNumber" #spouseCardNumber="ngModel" [ngClass]="{ 'is-invalid': f.submitted && spouseCardNumber.invalid }" required  />
                      <div *ngIf="f.submitted && spouseCardNumber.invalid &&hasSpouse" class="invalid-feedback">
                        <div *ngIf="spouseCardNumber.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
             
            </div>
            <div id="profile" role="tabpanel" aria-labelledby="profile-tab"  class="tab-pane fade px-4 ">
              <div class="row">
                <div class="col-md-6">                 
             
                  <div class="form-group row">
                    <label for="mobile" class="col-lg-4 col-form-label">มือถือ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mobile" [(ngModel)]="obj.mobile" #mobile="ngModel" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required />
                      <div *ngIf="f.submitted && mobile.invalid" class="invalid-feedback">
                        <div *ngIf="mobile.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="phone" class="col-lg-4 col-form-label">เบอร์บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="phone" [(ngModel)]="obj.phone" #phone="ngModel" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingAddressId" class="col-lg-4 col-form-label">ที่อยู่สำหรับจัดส่งเอกสาร <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.mailingAddressList"  name="mailingAddressId" [(ngModel)]="obj.mailingAddressId" #mailingAddressId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && mailingAddressId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && mailingAddressId.invalid" class="invalid-feedback">
                        <div *ngIf="mailingAddressId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="email" class="col-lg-4 col-form-label">อีเมล</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="email" [(ngModel)]="obj.email" #email="ngModel"   />                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="fax" class="col-lg-4 col-form-label">แฟกซ์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="fax" [(ngModel)]="obj.fax" #fax="ngModel"    />
                   
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="mailingMethodId" class="col-lg-4 col-form-label">วิธีการรับเอกสาร <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.mailingMethodList"   name="mailingMethodId" [(ngModel)]="obj.mailingMethodId" #mailingMethodId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && mailingMethodId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && mailingMethodId.invalid" class="invalid-feedback">
                        <div *ngIf="mailingMethodId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>

                </div>  
              </div>   
           

              <h4 class="text-primary">ที่อยู่ตามบัตร</h4>
              <hr>
              <div class="row" >
                <div class="col-md-6">                 
                  <div class="form-group row">
                    <label for="addr" class="col-lg-4 col-form-label">เลขที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="addr" [(ngModel)]="obj.addr" #addr="ngModel" [ngClass]="{ 'is-invalid': f.submitted && addr.invalid }" required  />
                      <div *ngIf="f.submitted && addr.invalid" class="invalid-feedback">
                        <div *ngIf="addr.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="moo" class="col-lg-4 col-form-label">หมู่ที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="moo" [(ngModel)]="obj.moo" #moo="ngModel"  />
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="building" class="col-lg-4 col-form-label">อาคาร/หมู่บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="building" [(ngModel)]="obj.building" #building="ngModel" />
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="floor" class="col-lg-4 col-form-label">ชั้น</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="floor" [(ngModel)]="obj.floor" #floor="ngModel"   />                     
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="soi" class="col-lg-4 col-form-label">ซอย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="soi" [(ngModel)]="obj.soi" #floor="ngModel"   />                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="road" class="col-lg-4 col-form-label">ถนน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="road" [(ngModel)]="obj.road" #road="ngModel"  />                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="provinceId" class="col-lg-4 col-form-label">จังหวัด</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.provinceList"   name="provinceId" [(ngModel)]="obj.provinceId" #provinceId="ngModel" (ngModelChange)="changeProvince($event)" [ngClass]="{ 'is-invalid': f.submitted && provinceId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && provinceId.invalid" class="invalid-feedback">
                        <div *ngIf="provinceId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="districtId" class="col-lg-4 col-form-label">เขต/อำเภอ</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.districtList"   name="districtId" [(ngModel)]="obj.districtId" #districtId="ngModel"  (ngModelChange)="changeDistrict($event)" [ngClass]="{ 'is-invalid': f.submitted && districtId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && districtId.invalid" class="invalid-feedback">
                        <div *ngIf="districtId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="subDistrictId" class="col-lg-4 col-form-label">แขวง/ตำบล</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.subDistrictList"   name="subDistrictId" [(ngModel)]="obj.subDistrictId" #subDistrictId="ngModel"   (ngModelChange)="changeSubDistrict($event)" [ngClass]="{ 'is-invalid': f.submitted && subDistrictId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && subDistrictId.invalid" class="invalid-feedback">
                        <div *ngIf="subDistrictId.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="zipCode" class="col-lg-4 col-form-label">รหัสไปรษณีย์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="zipCode" [(ngModel)]="obj.zipCode" #zipCode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && zipCode.invalid }" required readonly  />
                      <div *ngIf="f.submitted && zipCode.invalid" class="invalid-feedback">
                        <div *ngIf="zipCode.errors.required">ระบุรหัสพนักงาน</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              
              <h4 class="text-primary">ที่อยู่ที่ติดต่อ</h4>
              <hr>
              <div class="row" >
                <div class="col-md-6">                 
                  <div class="form-group row">
                    <label for="contactAddr" class="col-lg-4 col-form-label">เลขที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="contactAddr" [(ngModel)]="obj.contactAddr" #contactAddr="ngModel" [ngClass]="{ 'is-invalid': f.submitted && contactAddr.invalid }" required  />
                      <div *ngIf="f.submitted && contactAddr.invalid" class="invalid-feedback">
                        <div *ngIf="contactAddr.errors.required">Field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="contactMoo" class="col-lg-4 col-form-label">หมู่ที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="contactMoo" [(ngModel)]="obj.contactMoo" #contactMoo="ngModel"  />
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="contactBuilding" class="col-lg-4 col-form-label">อาคาร/หมู่บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="ContactBuilding" [(ngModel)]="obj.contactBuilding" #contactBuilding="ngModel" />
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="contactFloor" class="col-lg-4 col-form-label">ชั้น</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="contactFloor" [(ngModel)]="obj.contactFloor" #contactFloor="ngModel"   />                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="contactSoi" class="col-lg-4 col-form-label">ซอย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="contactSoi" [(ngModel)]="obj.contactSoi" #contactSoi="ngModel"   />                     
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="contactRoad" class="col-lg-4 col-form-label">ถนน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="contactRoad" [(ngModel)]="obj.contactRoad" #contactRoad="ngModel"  />                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="contactProvinceId" class="col-lg-4 col-form-label">จังหวัด</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.provinceList"   name="contactProvinceId" [(ngModel)]="obj.contactProvinceId" #contactProvinceId="ngModel" (ngModelChange)="changeProvince2($event)" required ></ng-select>
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="contactDistrictId" class="col-lg-4 col-form-label">เขต/อำเภอ</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.contactDistrictList"   name="contactDistrictId" [(ngModel)]="obj.contactDistrictId" #contactDistrictId="ngModel"  (ngModelChange)="changeDistrict2($event)"  ></ng-select>
                      
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="contactSubDistrictId" class="col-lg-4 col-form-label">แขวง/ตำบล</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.contactSubDistrictList"   name="contactSubDistrictId" [(ngModel)]="obj.contactSubDistrictId" #contactSubDistrictId="ngModel"   (ngModelChange)="changeSubDistrict2($event)" ></ng-select>
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="contactZipCode" class="col-lg-4 col-form-label">รหัสไปรษณีย์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="contactZipCode" [(ngModel)]="obj.contactZipCode" #contactZipCode="ngModel" readonly  />
                     
                    </div>
                  </div>
                </div>
              </div> 

              <h4 class="text-primary">ที่อยู่อื่นๆ</h4>
              <hr>
              <div class="row" >
                <div class="col-md-6">                 
                  <div class="form-group row">
                    <label for="mailingAddr" class="col-lg-4 col-form-label">เลขที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingAddr" [(ngModel)]="obj.mailingAddr" #mailingAddr="ngModel"  />
                      
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingMoo" class="col-lg-4 col-form-label">หมู่ที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingMoo" [(ngModel)]="obj.mailingMoo" #mailingMoo="ngModel"  />
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingBuilding" class="col-lg-4 col-form-label">อาคาร/หมู่บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingBuilding" [(ngModel)]="obj.mailingBuilding" #mailingBuilding="ngModel" />
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingFloor" class="col-lg-4 col-form-label">ชั้น</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingFloor" [(ngModel)]="obj.mailingFloor" #mailingFloor="ngModel"   />                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingSoi" class="col-lg-4 col-form-label">ซอย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingSoi" [(ngModel)]="obj.mailingSoi" #mailingSoi="ngModel"   />                     
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="mailingRoad" class="col-lg-4 col-form-label">ถนน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingRoad" [(ngModel)]="obj.mailingRoad" #mailingRoad="ngModel"  />                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="mailingProvinceId" class="col-lg-4 col-form-label">จังหวัด</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.provinceList"   name="mailingProvinceId" [(ngModel)]="obj.mailingProvinceId" #mailingProvinceId="ngModel" (ngModelChange)="changeProvince4($event)" required ></ng-select>
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingDistrictId" class="col-lg-4 col-form-label">เขต/อำเภอ</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.mailingDistrictList"   name="mailingDistrictId" [(ngModel)]="obj.mailingDistrictId" #mailingDistrictId="ngModel"  (ngModelChange)="changeDistrict4($event)"  ></ng-select>
                      
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="mailingSubDistrictId" class="col-lg-4 col-form-label">แขวง/ตำบล</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.mailingSubDistrictList"   name="mailingSubDistrictId" [(ngModel)]="obj.mailingSubDistrictId" #mailingSubDistrictId="ngModel"   (ngModelChange)="changeSubDistrict4($event)" ></ng-select>                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="mailingZipCode" class="col-lg-4 col-form-label">รหัสไปรษณีย์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="mailingZipCode" [(ngModel)]="obj.mailingZipCode" #mailingZipCode="ngModel"  readonly  />
                      
                    </div>
                  </div>
                </div>
              </div> 

            </div>
            <div id="contact" role="tabpanel" aria-labelledby="contact-tab"   class="tab-pane fade px-4 ">
              <div class="row">
                <div class="col-md-6">                 
             
                  <div class="form-group row">
                    <label for="occupationId" class="col-lg-4 col-form-label">อาชีพ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.occupationList"   name="occupationId" [(ngModel)]="obj.occupationId" #occupationId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && occupationId.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && occupationId.invalid" class="invalid-feedback">
                        <div *ngIf="occupationId.errors.required">ระบุอาชีพ</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="businessId" class="col-lg-4 col-form-label">ประเภทธุรกิจ</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.businessList"   name="businessId" [(ngModel)]="obj.businessId" #businessId="ngModel" ></ng-select>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeName" class="col-lg-4 col-form-label">ชื่อที่ทำงาน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeName" [(ngModel)]="obj.officeName" #officeName="ngModel"  />
                      
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="jobTitle" class="col-lg-4 col-form-label">ตำแหน่งงาน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="jobTitle" [(ngModel)]="obj.jobTitle" #jobTitle="ngModel"  />
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="investmentObjective" class="col-lg-4 col-form-label">วัตถุประสงค์การลงทุน <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.investmentObjectiveList" [optionValue]="'name'" [multiple]="true" name="investmentObjective" [(ngModel)]="obj.investmentObjective" #investmentObjective="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && investmentObjective.invalid }" required ></ng-select>
                      <div *ngIf="f.submitted && investmentObjective.invalid" class="invalid-feedback">
                        <div *ngIf="investmentObjective.errors.required">ระบุประเภทธุรกิจ</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="investmentObjectiveOther" class="col-lg-4 col-form-label">วัตถุประสงค์การลงทุนอื่นๆ</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="investmentObjectiveOther" [(ngModel)]="obj.investmentObjectiveOther" #investmentObjectiveOther="ngModel"  />                       
                    </div>
                  </div>

                </div>
                <div class="col-md-6">  
                 
                  <div class="form-group row">
                    <label for="sourceOfIncomeId" class="col-lg-4 col-form-label">แหล่งที่มาของรายได้ <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.sourceOfIncomeList"  [multiple]="true"  [optionValue]="'name'" name="sourceOfIncome" [(ngModel)]="obj.sourceOfIncome" #sourceOfIncome="ngModel"  ></ng-select>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="sourceOfIncomeOther" class="col-lg-4 col-form-label">แหล่งที่มาของรายได้อื่น</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="sourceOfIncomeOther" [(ngModel)]="obj.sourceOfIncomeOther" #sourceOfIncomeOther="ngModel"  />                       
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="incomeId" class="col-lg-4 col-form-label">รายได้ต่อเดือน <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.incomeList"  name="incomeId" [(ngModel)]="obj.incomeId" #incomeId="ngModel"  ></ng-select>
                     
                    </div>
                  </div>
                  
                  <div class="form-group row">
                    <label for="countryIncomeId" class="col-lg-4 col-form-label">ประเทศของแหล่งที่มาของรายได้/เงินทุน</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.countryList"   name="countryIncomeId" [(ngModel)]="obj.countryIncomeId" #countryIncomeId="ngModel"  ></ng-select>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="income" class="col-lg-4 col-form-label">มูลค่าทรัพย์สิน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="income" [(ngModel)]="obj.income" #income="ngModel"  />
                     
                    </div>
                  </div>
                 
                
                </div>
              </div>  
              <h3 class="text-muted">ที่อยู่ทีทำงาน</h3>
              <hr>
              <div class="row" >
                <div class="col-md-6">                 
                  <div class="form-group row">
                    <label for="officeAddr" class="col-lg-4 col-form-label">เลขที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeAddr" [(ngModel)]="obj.officeAddr" #officeAddr="ngModel"  />
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeMoo" class="col-lg-4 col-form-label">หมู่ที่</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeMoo" [(ngModel)]="obj.officeMoo" #officeMoo="ngModel"  />
                       
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeBuilding" class="col-lg-4 col-form-label">อาคาร/หมู่บ้าน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeBuilding" [(ngModel)]="obj.officeBuilding" #officeBuilding="ngModel" />
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeFloor" class="col-lg-4 col-form-label">ชั้น</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeFloor" [(ngModel)]="obj.officeFloor" #officeFloor="ngModel"   />                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeSoi" class="col-lg-4 col-form-label">ซอย</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeSoi" [(ngModel)]="obj.officeSoi" #officeSoi="ngModel"   />                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeRoad" class="col-lg-4 col-form-label">ถนน</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeRoad" [(ngModel)]="obj.officeRoad" #officeRoad="ngModel"  />                      
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label for="officeProvinceId" class="col-lg-4 col-form-label">จังหวัด</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.provinceList"   name="officeProvinceId" [(ngModel)]="obj.officeProvinceId" #officeProvinceId="ngModel" (ngModelChange)="changeProvince3($event)"></ng-select>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeDistrictId" class="col-lg-4 col-form-label">เขต/อำเภอ</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.officeDistrictList"   name="officeDistrictId" [(ngModel)]="obj.officeDistrictId" #officeDistrictId="ngModel"  (ngModelChange)="changeDistrict3($event)" ></ng-select>
                     
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="officeSubDistrictId" class="col-lg-4 col-form-label">แขวง/ตำบล</label>
                    <div class="col-lg-8">
                      <ng-select  [options]="data.officeSubDistrictList"   name="officeSubDistrictId" [(ngModel)]="obj.officeSubDistrictId" #officeSubDistrictId="ngModel"   (ngModelChange)="changeSubDistrict3($event)"  ></ng-select>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="officeZipCode" class="col-lg-4 col-form-label">รหัสไปรษณีย์</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="officeZipCode" [(ngModel)]="obj.officeZipCode" #officeZipCode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && officeZipCode.invalid }"  readonly  />
                    
                    </div>
                  </div>
                </div>
              </div> 
            </div>
            <div id="benefit" role="tabpanel" aria-labelledby="contact-tab"  *ngIf="false" class="tab-pane fade px-4 ">              
            </div>
            <div id="bank" role="tabpanel" aria-labelledby="contact-tab"  class="tab-pane fade px-4 ">          
 
               <button type="button" class="btn btn-info mb-20" (click)="openModal()" *ngIf="obj.id">Add BankAccount</button>
                <div class="row ">
                  <div class="col-md-12">
                    <table class="table table-stripe">
                      <tr>
                        <th>ประเภทบัญชี</th>
                        <th>ธนาคาร</th>                       
                        <th>สาขา</th>
                        <th>เลขบัญชี</th>
                        <th>ชื่อบัญชี</th>
                        <th>สถานะ</th>
                        <th></th>
                      </tr>
                      <tr  *ngFor="let a of data.bankaccountList">
                        <td>{{a.bankAccountTypeName}}</td>
                        <td>{{a.bankCode}}</td>
                        <td>{{a.branchName}}</td>
                        <td>{{a.accountNo}}</td>
                        <td>{{a.accountName}}</td>
                        <td>
                          <i class="fa fa-check text-success" style="font-size:16px"  *ngIf="a.active==1"></i>
                          <i class="fa fa-times text-danger" style="font-size:16px"  *ngIf="a.active==0"></i>
                        </td>
                        <td>
                          <button type="button" class="btn btn-success btn-xs mr-10" (click)="onEditBankAccount(a)"><i class="fa fa-edit"></i></button>
                          <button type="button"  class="btn btn-danger btn-xs"(click)="onDeleteBankAccount(a)"><i class="fa fa-trash"></i></button>
                        </td>
                      </tr>

                    </table>
                  </div>
                   
                </div>
            </div>
            <div id="other" role="tabpanel" aria-labelledby="contact-tab"   class="tab-pane fade px-4 ">
              
              <div class="row">
                <div class="col-md-6">                 
             
                  <div class="form-group row">
                    <label for="occupationId" class="col-lg-4 col-form-label">Omnibus <span class="required">*</span></label>
                    <div class="col-lg-8">
                      <ng-checkbox [(ngModel)]="obj.isOmnibus" name="isOmnibus"></ng-checkbox>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="businessId" class="col-lg-4 col-form-label">เป็นผู้ใกล้ชิดตำแหน่งทางการเมือง</label>
                    <div class="col-lg-8">
                      <div class="form-inline">
                        <div class="form-check mr-2">
                          <ng-checkbox [(ngModel)]="obj.isPoliticalRelatedPerson" name="isPoliticalRelatedPerson"></ng-checkbox>                          
                        </div>
                       
                        <div class="form-group">
                          <input type="text"  class="form-control" [disabled]="!obj.isPoliticalRelatedPerson"  placeholder="ตำแหน่งทางการเมือง" name="PoliticalRelatedPersonJobPosition" [(ngModel)]="obj.PoliticalRelatedPersonJobPosition" #isPoliticalRelatedPerson="ngModel"  />
                      
                        </div>                     
                      </div>
                     
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="suitabilityRiskLevel" class="col-lg-4 col-form-label">ระดับแบบประเมินความเสี่ยง </label>
                    <div class="col-lg-2">                    
                      <select   name="documentTypeId" [(ngModel)]="obj.suitabilityRiskLevel" class="form-control" >
                        <option [value]="0">--</option>
                        <option *ngFor="let i of riskLevelList" [value]="i" [selected]="obj.suitabilityRiskLevel==i">{{i}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="bondCredit" class="col-lg-4 col-form-label">วงเงินค้าตราสารหนี้</label>
                    <div class="col-lg-8">
                      <input type="text" class="form-control" name="bondCredit" [(ngModel)]="obj.bondCredit" #bondCredit="ngModel"  />
                       
                    </div>
                  </div>
                    
                </div>
              </div>
            </div>
            <div id="files" role="tabpanel" aria-labelledby="contact-tab" class="tab-pane fade px-4 ">
                <div class="row" *ngIf="obj.id">
                  <div class="col-md-3">
                    <ng-select  [options]="data.documentTypeList"   name="documentTypeId" [(ngModel)]="documentTypeId"  ></ng-select>
                  </div>
                  <div class="col-md-3">
                    
                    <label title="Upload image file" for="inputImage" class="btn btn-info" >
                      <input type="file"  #fileInput (change)="onFileSelected($event)"  name="file" id="inputImage" style="display:none" >
                      <i class="fa fa-upload" ></i> Attach File
                  </label>
                  </div>
                  
                </div>
                
                <div class="row ">
                  <div class="col-md-12">
                    <table class="table table-striped">
                      <tr>
                        <th>Document Type</th>
                        <th>File Type</th>              
                        <th>Size(KB.)</th> 
                        <th>CreateBy</th>                    
                        <th>CreateDate</th>
                        <th></th>
                      </tr>
                      <tr  *ngFor="let a of data.attachmentList">
                        <td>{{a.documentTypeName}}</td>
                        <td>{{a.fileType}}</td>  
                        <td>{{a.fileSize/1000}}</td>
                        <td>{{a.created}}</td>
                        <td>{{a.createDate}}</td>
                        <td>
                          <button type="button"   class="btn btn-info btn-xs mr-10" (click)="onDownloadFile(a)"><i class="fa fa-file-pdf-o"></i></button>
                          <button type="button"  class="btn btn-danger btn-xs"(click)="onDeleteFile(a)"><i class="fa fa-trash"></i></button>
                        </td>
                      </tr>

                    </table>
                  </div>
                   
                </div>
            </div>
          </div>

          <!--<div class="row">
            <div class="col-lg-10">
              
                
                 
              </form>
            </div>
          </div>-->
        </div>
        <div class="ibox-footer text-right" >
          <button class="btn btn-success mr-10" type="button" (click)="changeStatus()"  *ngIf="obj.status=='SUBMITTED'" >Approved</button>
          <button class="btn btn-primary mr-10" type="submit" (click)="onSubmit()"  >บันทึก</button>
          <a class="btn btn-white" [routerLink]="['/account/eoacc']">ยกเลิก</a>
          
        </div>
      </div>
    </div>
  </div>
</form>

<ng-modal #modalRef  (onCompleted)="onCompletionModal($event)" > 
</ng-modal> 
</div>
