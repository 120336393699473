import { Component, EventEmitter, Injector, Input,Output,Type,ViewChild, ViewContainerRef,} from '@angular/core';
import {  NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ng-modal',
  templateUrl: './ng-modal.template.html',

})
export class NgModalComponent {

  
  @ViewChild('modalContent', { read: ViewContainerRef, static: true }) modalContent: ViewContainerRef;
  @Input() title: string;
  @Output() onCompleted: EventEmitter<any> = new EventEmitter<any>()
  private modalRef: NgbModalRef;

  constructor(
      private modalService: NgbModal,
      private injector: Injector
    ) {}

  show(component: Type<any>,obj:any) {

    this.modalRef = this.modalService.open(component, { centered: true, injector: this.injector });
    this.modalRef.result
    .then((data) => {this.onCompleted.emit(data)})
    .catch(() => this.onCompleted.emit());
    this.modalRef.componentInstance.data =obj;
  }
 
  completed() {
    this.onCompleted.emit()
  }
  hide() {
    this.modalRef.dismiss();
  }
}