import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { NgTableComponent } from "src/app/components/forms/ng-table.component";
import { NgTableColumn } from "src/app/components/forms/ng-table.inferface";
import { DataService } from "src/app/shared/services/dataService";

@Component({
  selector: 'sql-analyzer',
  templateUrl: './sql-analyzer.component.html'
})


export class SqlAnalyzerComponent implements OnInit {
  query: string = 'select * from aspnetusers';
  results: any = {};
  tables :any ;
  columns: Array<NgTableColumn> = [];
  tableName:string ='';
  @ViewChild(NgTableComponent) table: NgTableComponent;
  sqlCommand: any;
  xquery: any;

  constructor(
    private toastr: ToastrService,
    private service: DataService,
  ) {

  }

  async ngOnInit() {
    this.getTable()
   }

  executeQuery() {
    this.table.filterText = this.query;;

    this.table.refresh()
  }

  public datasource = (params: any): Observable<any> => {
    return this.service.post(`sqlanalyzer/query`, params);
  };

  onChange(e) {
   
    this.table.filterText = `select * from ${e} ORDER BY ID DESC`;
    this.query=this.table.filterText ;
    this.table.refresh()
     //console.log(e)
     //this.query="select * from "
  }
  getTable() {
    this.service.get("sqlanalyzer/gettable")
      .subscribe(response => {
        this.tables = response;
      }, error => {
        this.toastr.error(error.message, 'Error!');
      })
  };
}