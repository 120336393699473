<div class="ng-table">
<div class="table-filter" *ngIf="allowFilter">
  <input
    class="form-control"
    type="text"
    [(ngModel)]="filterText"
    (keyup.enter)="filter()"
    placeholder="Filter"
  />
</div>

<div class="table-scroll">
<table class="table table-striped table-sticky-header">
  <thead>
    <tr>
      <ng-container *ngIf="allowCheckbox">
        <th class="center"><label><input type="checkbox" (change)="onCheckAllChange($event.target.checked)" [checked]="isAllChecked()" /></label></th>
      </ng-container>
      <ng-container *ngFor="let column of columns; let i = index">
       
        <ng-container *ngIf="column.sort || column.sort==undefined else useTemplate">          
          <th (click)="onSort(column)" [ngStyle]="{'width': column.width, 'text-align': column.align ? column.align : 'left'}"> {{column.title}} 
            <i  class="fa" [ngClass]="sortIcon(column)"></i>
          </th>    
        </ng-container>      
        <ng-template #useTemplate>
            <th  [ngStyle]="{'width': column.width,'text-align': column.align ? column.align : 'left'}" > {{column.title}}</th>
        </ng-template> 

      </ng-container>  
    </tr>
  </thead>
  <tbody *ngIf="data && data.length > 0">
    <tr *ngFor="let row of data" (click)="onSelectRow(row)">     
      <ng-container *ngIf="allowCheckbox">
          <td class="center"><input type="checkbox" [checked]="checkedItems.has(row)" (change)="onItemCheckChange(row, $event.target.checked)" /></td>
      </ng-container>
      <ng-container *ngFor="let column of columns">     
 
        
          <ng-container *ngIf="!column.template; else useTemplate">         
           <td  [ngStyle]="{'text-align': column.align ? column.align : 'left'}">
            {{ formatValue(row[column.name], column.format) }}
           </td> 
          </ng-container>
          <ng-template #useTemplate>
           <td [ngStyle]="{'text-align': column.align ? column.align : 'left'}">
            <ng-container *ngTemplateOutlet="templateMap[column.template]; context: { row: row }"></ng-container> 
           </td> 
          </ng-template>     
        
      </ng-container>
    </tr>
  </tbody>
  <tbody *ngIf="!data || data.length === 0">
    <tr>
      <td [attr.colspan]="columns.length + (allowCheckbox ? 1 : 0)" class="center">No data available.</td>
    </tr>
  </tbody>
</table>
</div>

<nav class="navigation">
  <div class="info-result"  *ngIf="data.length > 0">
    <span>Showing {{(currentPage-1)*pageSize + 1}} to {{(currentPage-1)*pageSize + data.length | number}} of {{totalItems | number}} entries</span>
  </div>
  <ul class="pagination float-right"  *ngIf="data.length > 0 && allowPaging">
    <li class="page-item" [ngClass]="{disabled: currentPage === 1}">
      <a class="page-link" (click)="onFirstPage()" aria-label="First">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li class="page-item" [ngClass]="{disabled: currentPage === 1}">
      <a class="page-link" (click)="onPrevPage()" aria-label="Previous">
        <span aria-hidden="true">&lsaquo;</span>
      </a>
    </li>
    <li *ngFor="let page of getPages()" class="page-item" [ngClass]="{active: currentPage === page}">
      <a class="page-link" (click)="onPage(page)">{{page}}</a>
    </li>
    <li class="page-item" [ngClass]="{disabled: currentPage === totalPages()}">
      <a class="page-link" (click)="onNextPage()" aria-label="Next">
        <span aria-hidden="true">&rsaquo;</span>
      </a>
    </li>
    <li class="page-item" [ngClass]="{disabled: currentPage === totalPages()}">
      <a class="page-link" (click)="onLastPage()" aria-label="Last">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>

<!--<nav aria-label="Table Navigation">
  <ul class="pagination">
    <li class="page-item" [ngClass]="{disabled: currentPage === 1}">
      <a class="page-link" href="#" aria-label="Previous" (click)="onPrevPage()">
        <i class="[pageIcon('fa-angle-left', currentPage === 1)]"></i>
      </a>
    </li>


    <li class="page-item" [ngClass]="{disabled: currentPage === totalPages()}">
      <a class="page-link" href="#" aria-label="Next" (click)="onNextPage()">
        <i class="[pageIcon('fa-angle-right', currentPage === totalPages())]"></i>
      </a>
    </li>
  </ul>
</nav>-->
</div>