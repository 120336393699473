<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h3>Title List</h3>
            <!--<iboxtools></iboxtools>-->
          </div>
          <div class="ibox-content">
            <ng-table 
           
            [columns]="columns" 
            [datasource]="datasource" 
            [allowFilter]="true"
            [allowCheckbox]="false"
            [defaultSortColumn]="'id'">
              <ng-template #action   let-item="row">
                <button class="btn btn-success btn-xs" (click)="onEdit(item)"><i class="fa fa-edit"></i></button>
                <button class="btn btn-danger btn-xs"(click)="onDelete(item)"><i class="fa fa-trash"></i></button>
              </ng-template>    
             
            </ng-table>
          </div>
          <div class="ibox-footer">
            <a class="btn btn-success" [routerLink]="['/setup/title/create']" [style.display]="permission.isAdd == true ? 'inline-block' : 'none'">AddNew</a>
          </div>
        </div>        
  </div>
</div>
</div>