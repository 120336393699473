<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu" #menu>
          <li class="nav-header">
              <div class="dropdown profile-element">
                  <img alt="image" class="rounded-circle" src="assets/img/profile_small.jpg"/>
                  <a data-toggle="dropdown" class="dropdown-toggle" href="empty_page.html#">
                    <span class="block m-t-xs font-bold">{{userInfo.fullName}}</span>                     
                   <span class="text-muted text-xs block">{{userInfo.roleName}} </span>
                  </a>
                 
              </div>
              <div class="logo-element">
                  IN+
              </div>
          </li>
       
          <li>
              <a routerLink="/dashobard"><i class="fa fa-diamond"></i> <span class="nav-label">Dashboard</span></a>
          </li>
          <li *ngFor="let menu of menuList; let i=index" [ngClass]="{active: activeRouteMain(menu.code)}">
            <a [attr.href]="'#'"><i [ngClass]="menu.icon"></i><span class="nav-label">{{menu.name}}</span><span class="fa arrow"></span></a>
            <ul class="submenu nav nav-second-level collapse">
              <li *ngFor="let sub of menu.sysMenuItem; let k=index" [ngClass]="{active: activeRouteSub(sub.url)}" [style.display]="sub.status == 1 ? 'block' : 'none'"><a id="{{sub.id}}" href="{{sub.url.includes('http') ? [sub.url] : ['#'+sub.url]}}" target="{{sub.url.includes('http') ? '_blank': '_self' }}" > {{sub.name}}</a></li>
            </ul>
          </li>
      </ul>

  </div>
</nav>