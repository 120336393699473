
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringDecoder } from 'string_decoder';
@Injectable()
export class DataService {

    private serverUrl: String;
    private config: string;

    constructor(private http: HttpClient) {
        this.serverUrl = environment.baseUrl + "/api/";
    }

    public get<T>(url: string): Observable<T> {
        return this.http.get<T>(this.serverUrl + url);
    }
    public post<T>(url: string, data: any): Observable<T> {
        return this.http.post<T>(this.serverUrl + url, JSON.stringify(data));
    }
    public update<T>(url: string, id: number, itemToUpdate: any): Observable<T> {
        return this.http.put<T>(this.serverUrl + url + id, JSON.stringify(itemToUpdate));
    }
    public delete<T>(url: string, id: number): Observable<T> {
        return this.http.get<T>(this.serverUrl + url + '/' + id);
    }
    public upload<T>(url: string, file: File, jsonData: any): Observable<T> {
   
        const formData: FormData = new FormData();
        formData.append('file', file,);
        formData.append('jsonData', JSON.stringify(jsonData));
        
        return this.http.post<T>(this.serverUrl + url, formData);
    }
    public download<T>(url: string): Observable<Blob> {
        return this.http.get(this.serverUrl + url, { responseType: 'blob' });
    }
}


