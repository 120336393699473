
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>เมนูย่อย</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" >
              <div class="row">
                <div class="col-lg-6 offset-md-12">
                 
                    <div class="form-group row">
                      <label for="code" class="col-lg-3 col-form-label">Code <span class="required">*</span></label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="code" [(ngModel)]="obj.code" #code="ngModel" [ngClass]="{ 'is-invalid': f.submitted && code.invalid }" required />
                        <div *ngIf="f.submitted && code.invalid" class="invalid-feedback">
                          <div *ngIf="code.errors.required">Code is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-lg-3 col-form-label">Name <span class="required">*</span></label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="name" [(ngModel)]="obj.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                          <div *ngIf="name.errors.required">Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-lg-3 col-form-label">Description</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="description" [(ngModel)]="obj.description" #description="ngModel"    />
                      
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="url" class="col-lg-3 col-form-label">URL <span class="required">*</span></label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="url" [(ngModel)]="obj.url" #url="ngModel" [ngClass]="{ 'is-invalid': f.submitted && url.invalid }" required />
                        <div *ngIf="f.submitted && url.invalid" class="invalid-feedback">
                          <div *ngIf="url.errors.required">URL is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="icon" class="col-lg-3 col-form-label">Icon <span class="required">*</span></label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="icon" [(ngModel)]="obj.icon" #icon="ngModel" [ngClass]="{ 'is-invalid': f.submitted && icon.invalid }" required />
                        <div *ngIf="f.submitted && icon.invalid" class="invalid-feedback">
                          <div *ngIf="icon.errors.required">Icon is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="seq" class="col-lg-3 col-form-label">Seq <span class="required">*</span></label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="seq" [(ngModel)]="obj.seq" #seq="ngModel" [ngClass]="{ 'is-invalid': f.submitted && seq.invalid }" required />
                        <div *ngIf="f.submitted && seq.invalid" class="invalid-feedback">
                          <div *ngIf="seq.errors.required">Seq is required</div>
                        </div>
                      </div>
                    </div>
                   
                    <div class="form-group row">
                      <label for="seq" class="col-lg-3 col-form-label">Main Menu <span class="required">*</span></label>
                      <div class="col-lg-9">
                        <ng-select  [options]="mainmenulist"   name="menuId" [(ngModel)]="obj.menuId" #menuId="ngModel"  [ngClass]="{ 'is-invalid': f.submitted && menuId.invalid }" required ></ng-select>
                        <div *ngIf="f.submitted && menuId.invalid" class="invalid-feedback">
                          <div *ngIf="menuId.errors.required">Menu is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-lg-3 col-form-label">Permission</label>
                      <div class="col-lg-9  d-flex justify-content-between">
                        <label class="ui-checkbox ui-checkbox-primary">
                          <input type="checkbox" (click)="chkPermissionView($event)" style="margin-right:5px" name="view" [(ngModel)]="obj.view" #view="ngModel" [ngClass]="{ 'is-invalid': f.submitted && view.invalid }" />
                          <span class="input-span"></span>View
                        </label>
                        <label class="ui-checkbox ui-checkbox-primary">
                          <input type="checkbox" (click)="chkPermissionAdd($event)" style="margin-right:5px" name="add" [(ngModel)]="obj.add" #add="ngModel" [ngClass]="{ 'is-invalid': f.submitted && add.invalid }" />
                          <span class="input-span"></span>Add
                        </label>
                        <label class="ui-checkbox ui-checkbox-primary">
                          <input type="checkbox" (click)="chkPermissionEdit($event)" style="margin-right:5px" name="edit" [(ngModel)]="obj.edit" #edit="ngModel" [ngClass]="{ 'is-invalid': f.submitted && edit.invalid }" />
                          <span class="input-span"></span>Edit
                        </label>
                        <label class="ui-checkbox ui-checkbox-primary">
                          <input type="checkbox" (click)="chkPermissionDel($event)" style="margin-right:5px" name="del" [(ngModel)]="obj.del" #del="ngModel" [ngClass]="{ 'is-invalid': f.submitted && del.invalid }" />
                          <span class="input-span"></span>Delete
                        </label>
                        <label class="ui-checkbox ui-checkbox-primary">
                          <input type="checkbox" (click)="chkPermissionApprove($event)" style="margin-right:5px" name="approve" [(ngModel)]="obj.approve" #approve="ngModel" [ngClass]="{ 'is-invalid': f.submitted && approve.invalid }" />
                          <span class="input-span"></span>Approve
                        </label>
                         
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="status" class="col-lg-3 col-form-label">Status</label>
                      <div class="col-lg-9">                        
                        <label class="switch"><input name="status"   #status="ngModel" [(ngModel)]="obj.status"  type="checkbox"  [checked]="obj.status === 1"><span class="slider round"></span></label>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>                     
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/admin/submenu']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>   
  </form>
</div>
