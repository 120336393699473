import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NgFormModule } from "../components/forms/ng-form.module";
import { LoginComponent } from "./login/login.component";
import { LoaderService } from "../shared/services/services.loading";
import { AuthGuard } from "../shared/auth/auth.guard";
import { AppComponent } from "../app.component";
import { HttpClientModule } from "@angular/common/http";
import { DataService } from "../shared/services/dataService";
import { AuthService } from "../shared/auth/auth.service";
import { UsersComponent } from "./users/users.component";
import { ToastrModule } from 'ngx-toastr';
import { UsersFormComponent } from "./users/users-form.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccountComponent } from "./account/account.component";
import { AccountFormComponent } from "./account/account-form.component";
import { SettingFormComponent } from "./setting/setting-form.component";
import { RoleComponent } from "./roles/roles.component";
import { RoleFormComponent } from "./roles/roles-form.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { MainMenuComponent } from "./menu/main-menu.component";
import { MainMenuFormComponent } from "./menu/main-menu-form.component";
import { SubMenuComponent } from "./menu/sub-menu.component";
import { SubMenuFormComponent } from "./menu/sub-menu-form.component";
import { TrueFalseValueDirective } from "../components/directive/true-false-value.directive";
import { GeneralComponent } from "./general/general.component";
import { GeneralFormComponent } from "./general/general-form.component";
import { TitleComponent } from "./title/title.component";
import { TitleFormComponent } from "./title/title-form.component";
import { CountryComponent } from "./country/country.component";
import { CountryFormComponent } from "./country/country-form.component";
import { ProvinceComponent } from "./province/province.component";
import { ProvinceFormComponent } from "./province/province-form.component";
import { PersonalComponent } from "./personal/personal.component";
import { SqlAnalyzerComponent } from "./tools/sql-analyzer.component";
import { DipChipComponent } from "./personal/dipchop-form.component";
import { UsersChangePasswordComponent } from "./users/users-changepassword.component";
import { PasswordMatchDirective } from "../components/directive/PasswordMatchDirective";
import { AlertService } from "../shared/services/AlertService";
import { BankComponent } from "./bank/bank.component";
import { BankFormComponent } from "./bank/bank-form.component";
import { BankAccountFormComponent } from "./account/bankaccount-form.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InvestorComponent } from "./investor/investor.component";
import { InvestorFormComponent } from "./investor/investor-form.component";
 
@NgModule({
  declarations: [
    TrueFalseValueDirective,
    DashboardComponent,  
    SettingFormComponent,
    LoginComponent ,
    UsersComponent, 
    AccountComponent,
    AccountFormComponent,
    UsersFormComponent,
    RoleComponent,
    RoleFormComponent,
    NotfoundComponent,
    MainMenuComponent,
    MainMenuFormComponent,
    SubMenuComponent,
    SubMenuFormComponent,
    GeneralComponent,
    GeneralFormComponent,
    TitleComponent,
    TitleFormComponent,
    CountryComponent,
    CountryFormComponent,
    ProvinceComponent,
    ProvinceFormComponent,
    PersonalComponent,
    DipChipComponent,
    SqlAnalyzerComponent,
    UsersChangePasswordComponent,
    PasswordMatchDirective,
    BankComponent,
    BankFormComponent,
    BankAccountFormComponent,
    InvestorComponent,
    InvestorFormComponent
  ],
  imports: [
    ReactiveFormsModule,
    ToastrModule.forRoot(),  
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    RouterModule,
    NgFormModule,
    HttpClientModule,
    
  ],
  providers: [    
    AuthGuard,LoaderService,DataService,AuthService,NgbActiveModal 
  ],
  bootstrap: [AppComponent]
})

export class NgViewModule {}
