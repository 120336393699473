import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'users-changepassword',
  templateUrl: './users-changepassword.component.html'
})

export class UsersChangePasswordComponent  implements OnInit{
    loading = false;
    submitted = false;
    routeParams = null;
    obj :any= { };
    id = 0;
    form: FormGroup = new FormGroup({});
    permission: any = {};
    constructor(
      private http: HttpClient,
      private router: Router,
      private toastr: ToastrService,
      private activeRoute: ActivatedRoute,
      private service: DataService,
      private authService: AuthService,
     
    ) {
   
    }
 
  ngOnInit()  {
    // adding the lifecycle hook ngOnInit
    //this.service.get('users/list').subscribe(data => {
    //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
    ///});
    //this.getCreate();
  
    //this.permission = await this.authService.getPermission("users");
    }
 
 
   
    onSubmit() {
      //this.submitted = true;
      //console.log(this.editForm.valid);

      //check validate form
      this.loading = true;
      this.service.post("users/changepassword", this.obj)
        .subscribe((response: any) => {
          this.loading = false;
          this.toastr.success(response, 'Succcess');
          this.router.navigate(['/admin/users']);
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
        });
    
    }

     
    reset() {
      //this.submitted = true;
      //console.log(this.editForm.valid);

      //check validate form
      this.loading = true;
      this.service.post("users/resest", this.obj)
        .subscribe((response: any) => {
          this.loading = false;
          this.toastr.success(response, 'Succcess');
          this.router.navigate(['/admin/users']);
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
        });
    
    }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}