import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
  
@Component({
    selector: 'setting-form',
    templateUrl: './setting-form.component.html'
})

export class SettingFormComponent  implements OnInit{
   
    submitted = false;
    routeParams = null;
    obj: any = {};
    roleslist = [];
    permission: any = {};
    id = 0;
    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private service: DataService,
        private authService: AuthService
    ) {
       
    }
 
  async ngOnInit(): Promise<void> {
      // adding the lifecycle hook ngOnInit
      //this.service.get('users/list').subscribe(data => {
      //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
      ///});
      
      this.routeParams = this.activeRoute.snapshot.params;
      this.getRow();
    }
 
    getRow() {
      this.service.get("appconfig/get")
        .subscribe(response => {
          var obj:any = response;
        
          obj.forEach(o => {
              //console.log(o);
              this.obj[o.configKey]=o.configValue;
          });
          
         //this.obj[o.ConfigKey.toString()]=o.ConfigValue;
          //console.log(this.obj);
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
     
    onSubmit() {
      console.log(this.obj)
      //this.submitted = true;
      //console.log(this.editForm.valid);

      //check validate form
      var data=[];
      for(var k in this.obj){
           data.push({'ConfigKey':k,'ConfigValue':this.obj[k]})
      };
      this.service.post("appconfig/post", data)
        .subscribe((response: any) => {
          this.toastr.success("Create or Update Completed.", 'Succcess');
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
          
        });
       
    }
}
