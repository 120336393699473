
  <div class="wrapper wrapper-content container animated fadeInRight">
    <div class="row">
    
      <div class="col-lg-12">
        <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate  autocomplete="off">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <ul id="myTab" role="tablist" class="nav nav-tabs nav-pills flex-column flex-sm-row text-center  border-0 rounded-nav">
              <li class="nav-item  ">
                <a   data-toggle="tab" href="#tab-1" role="tab"   class="nav-link border-0 text-uppercase  active">General</a>
              </li>
              <li class="nav-item ">
                <a  data-toggle="tab" href="#tab-2" role="tab"   class="nav-link border-0 text-uppercase ">E-OpenAccount</a>
              </li>
              <li class="nav-item ">
                <a  data-toggle="tab" href="#tab-4" role="tab"   class="nav-link border-0 text-uppercase ">DOPA & AMLO</a>
              </li>
              <li class="nav-item ">
                <a  data-toggle="tab" href="#tab-3" role="tab"   class="nav-link border-0 text-uppercase ">Mail</a>
              </li>
            </ul>  
          </div>
          <div class="ibox-content">
            <div class="tab-content">
              <div id="tab-1" class="tab-pane active">
                <div class="panel-body">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="systemName" class="col-lg-4 col-form-label">System Name</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="systemName" [(ngModel)]="obj['SYSTEM_NAME']" #systemName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && systemName.invalid }" required />
                        <div *ngIf="f.submitted && systemName.invalid" class="invalid-feedback">
                          <div *ngIf="systemName.errors.required">Sysetem Name is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="companyName" class="col-lg-4 col-form-label">Company Name</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="companyName" [(ngModel)]="obj['COMPANY_NAME']" #companyName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && companyName.invalid }" required />
                        <div *ngIf="f.submitted && companyName.invalid" class="invalid-feedback">
                          <div *ngIf="companyName.errors.required">Company Name is required</div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="companyAddress" class="col-lg-4 col-form-label">Company Address</label>
                      <div class="col-lg-8">
                        <textarea class="form-control" name="companyAddress" [(ngModel)]="obj['COMPANY_ADDRESS']" #companyAddress="ngModel" [ngClass]="{ 'is-invalid': f.submitted &&  companyAddress.invalid }" required>
                          
                        </textarea>
                        <div *ngIf="f.submitted && companyAddress.invalid" class="invalid-feedback">
                          <div *ngIf="companyAddress.errors.required">Company Address is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="formatDate" class="col-lg-4 col-form-label">Format Date</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="formatDate" [(ngModel)]="obj['FORMAT_DATE']" #formatDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && formatDate.invalid }" required />
                        <div *ngIf="f.submitted && formatDate.invalid" class="invalid-feedback">
                          <div *ngIf="formatDate.errors.required">Company Name is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="dopaLinkExpire" class="col-lg-4 col-form-label">Dopa Link Exp.(m)</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="dopaLinkExpire" [(ngModel)]="obj['DOPA_LINK_EXPIRE']" #formatDate="ngModel"   />
                      
                      </div>
                    </div>

                  </div>
                </div>   
              </div>
              <div id="tab-2" class="tab-pane">
                <div class="panel-body">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="brokerId" class="col-lg-4 col-form-label">Broker ID</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="brokerId" [(ngModel)]="obj['OACC_BROKER_ID']" #brokerId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && brokerId.invalid }" required />
                        <div *ngIf="f.submitted && brokerId.invalid" class="invalid-feedback">
                          <div *ngIf="brokerId.errors.required">BrokerId is required</div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="baseURL" class="col-lg-4 col-form-label">Base URL</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="baseURL" [(ngModel)]="obj['OACC_BASE_URL']" #baseURL="ngModel" [ngClass]="{ 'is-invalid': f.submitted &&  baseURL.invalid }" required />
                        <div *ngIf="f.submitted && baseURL.invalid" class="invalid-feedback">
                          <div *ngIf="baseURL.errors.required">Base URL is required</div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="prvateKey" class="col-lg-4 col-form-label">Prvate Key File</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="prvateKey" [(ngModel)]="obj['OACC_PRIVATE_KEY']" #prvateKey="ngModel" [ngClass]="{ 'is-invalid': f.submitted && prvateKey.invalid }" required />
                        <div *ngIf="f.submitted && prvateKey.invalid" class="invalid-feedback">
                          <div *ngIf="prvateKey.errors.required">Private Key is required</div>
                        </div>
                      </div>
                    </div>
            
                    <div class="form-group row">
                      <label for="status" class="col-lg-4 col-form-label">Status</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="status" [(ngModel)]="obj['OACC_STATUS']" #status="ngModel" [ngClass]="{ 'is-invalid': f.submitted && status.invalid }" required />
                        <div *ngIf="f.submitted && status.invalid" class="invalid-feedback">
                          <div *ngIf="status.errors.required">Private Key is required</div>
                        </div>
                      </div>
                    </div>
                   
                    <div class="form-group row">
                      <label for="ndidStatus" class="col-lg-4 col-form-label">NDID Status</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="ndidStatus" [(ngModel)]="obj['OACC_NDID_STATUS']" #ndidStatus="ngModel"  />
                         
                      </div>
                    </div>
                
                    <div class="form-group row">
                      <label for="prvateKey" class="col-lg-4 col-form-label">Last Days</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="period" [(ngModel)]="obj['OACC_PERIOD']" #period="ngModel" [ngClass]="{ 'is-invalid': f.submitted && period.invalid }" required   />
                        <div *ngIf="f.submitted && period.invalid" class="invalid-feedback">
                          <div *ngIf="period.errors.required">Last Days is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
              </div>
              <div id="tab-3" class="tab-pane">
                <div class="panel-body">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="emailServer" class="col-lg-4 col-form-label">Email Server</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="emailServer" [(ngModel)]="obj['EMAIL_SERVER']" #emailServer="ngModel" [ngClass]="{ 'is-invalid': f.submitted && emailServer.invalid }" required />
                        <div *ngIf="f.submitted && emailServer.invalid" class="invalid-feedback">
                          <div *ngIf="emailServer.errors.required">Mail Server is required</div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="emailPort" class="col-lg-4 col-form-label">Email Port</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="emailPort"  [(ngModel)]="obj['EMAIL_PORT']" #emailPort="ngModel" [ngClass]="{ 'is-invalid': f.submitted && emailPort.invalid }" required />
                        <div *ngIf="f.submitted && emailPort.invalid" class="invalid-feedback">
                          <div *ngIf="emailPort.errors.required">Mail Port is required</div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="emailUser" class="col-lg-4 col-form-label">Email User</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="emailUser"  [(ngModel)]="obj['EMAIL_USER']" #emailUser="ngModel"  />
                         
                      </div>
                    </div>
                 
                    <div class="form-group row">
                      <label for="emailPassword" class="col-lg-4 col-form-label">Email Passowrd</label>
                      <div class="col-lg-8">
                        <input type="password" class="form-control" name="emailPassword"  [(ngModel)]="obj['EMAIL_PASSWORD']" #emailPassword="ngModel"    />
                         
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="emailForm" class="col-lg-4 col-form-label">Email From</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="emailForm"  [(ngModel)]="obj['EMAIL_FROM']" #emailForm="ngModel" [ngClass]="{ 'is-invalid': f.submitted && emailPort.invalid }" required />
                        <div *ngIf="f.submitted && emailForm.invalid" class="invalid-feedback">
                          <div *ngIf="emailForm.errors.required">Mail User is required</div>
                        </div>
                      </div>
                    </div>
                   
                    <div class="form-group row">
                      <label for="emailFormName" class="col-lg-4 col-form-label">Email From Name</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="emailFormName"  [(ngModel)]="obj['EMAIL_FROMNAME']" #emailFormName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && emailPort.invalid }" required />
                        <div *ngIf="f.submitted && emailFormName.invalid" class="invalid-feedback">
                          <div *ngIf="emailFormName.errors.required">Mail Passowrd is required</div>
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>
              </div> 
              <div id="tab-4" class="tab-pane ">
                <div class="panel-body">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="dapClientId" class="col-lg-4 col-form-label">Client ID</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="dapClientId" [(ngModel)]="obj['DAP_CLIENT_ID']" #dapClientId="ngModel"  />
                        
                      </div>
                    </div>
                  
                    <div class="form-group row">
                      <label for="dapKeyName" class="col-lg-4 col-form-label">Key Name</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="dapKeyName" [(ngModel)]="obj['DAP_KEY_NAME']" #dapKeyName="ngModel" >
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="dapBaseURL" class="col-lg-4 col-form-label">URL</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="dapBaseURL" [(ngModel)]="obj['DAP_BASE_URL']" #dapBaseURL="ngModel" >
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="dapPrivateKey" class="col-lg-4 col-form-label">PRIVATE KEY</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="dapPrivateKey" [(ngModel)]="obj['DAP_PRIVATE_KEY']" #dapPrivateKey="ngModel" >
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="dapPdfPassword" class="col-lg-4 col-form-label">Password PDF</label>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" name="dapPdfPassword" [(ngModel)]="obj['DAP_PASSWORD_PDF']" #dapPdfPassword="ngModel" >
                      </div>
                    </div>

                    
                  </div>
                </div>   
              </div>
            </div>           
          </div>
          <div class="ibox-footer">
            <div class="row">
              <div class="col-lg-12 text-right">
                  <div class="clearfix"></div>
                  <button class="btn btn-primary" type="submit">Submit</button>
              
                </div>
              </div>
          </div>
      </div>
    </form>
      </div>
      
    </div>
  </div>
