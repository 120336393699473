import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import * as moment from 'moment';

declare var $: any;

const DATEPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgDatePickerComponent),
  multi: true
};

@Component({
  selector: 'ng-datepicker',
  templateUrl: './ng-datepicker.template.html',
  providers: [DATEPICKER_VALUE_ACCESSOR]
})
export class NgDatePickerComponent implements ControlValueAccessor {

  @Input() placeholder: string = '';
  @Input() format: string = 'YYYY-MM-DD';

  @ViewChild('datepicker') datepicker: ElementRef;

  onChange: any = () => { };
  onTouched: any = () => { };
  writeValue(value: any): void {
    const datepicker = $(this.datepicker.nativeElement);
    if(value) {
      const date=moment(value).format(this.format)
      datepicker.val(date)
    }

    //console.log(value)
    //if(value) datepicker.val(moment(value).format(this.format));
     //const date = new Date(value);
    //$(this.datepicker.nativeElement).datetimepicker('setDate', date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngAfterViewInit() {
    this.initDatepicker();
    
  }

  private initDatepicker() {
    const datepicker = $(this.datepicker.nativeElement).datetimepicker({
      format: this.format,      
      allowInputToggle: true,
    }).on('dp.change', (event) => {
     
      const selectedDate = event.date;
      this.onChange(selectedDate);
      this.validate();
    });

    if (this.placeholder) {
      datepicker.attr('placeholder', this.placeholder);
    }
  }

  validate(): { [key: string]: any } {
    const selectedDate = $(this.datepicker.nativeElement).datetimepicker('date');
    if (!selectedDate) {
      return { required: true };
    }
    return null;
  }

  setDisabledState(isDisabled: boolean): void {
    const datepicker = $(this.datepicker.nativeElement).datetimepicker();
    if (isDisabled) {
      datepicker.datetimepicker('disable');
    } else {
      datepicker.datetimepicker('enable');
    }
  }
}
