import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'ng-birthdate',
  templateUrl: './ng-birthdate.template.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgBirthDateComponent),
      multi: true
    }
  ]
})
export class NgBirthDateComponent implements ControlValueAccessor {

  years: string[];
  months: string[];
  days: string[];
 
  selectedYear: number ;
  selectedMonth: number  ;
  selectedDay: number  
  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() showMonth: boolean = true;
  @Input() showDay: boolean = true;

  constructor() {
    const currentYear = new Date().getFullYear()+543;
    this.years = this.generateRange(currentYear, 2470); // Reverse the array
    this.months = [ 'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    this.days = [  ...this.generateRange(31, 1).reverse()];

    
  }

  generateRange(start: number, end: number): string[] {
    return Array.from({ length: start - end + 1 }, (_, index) => (start - index).toString());
  }

  validate(): { [key: string]: any } {

    //console.log(this.selectedYear+','+this.selectedMonth+','+this.selectedDay)
    if (this.selectedYear == null) {
      return { required: true };
    }
  
    if (this.showMonth && this.selectedMonth == null) {
      return { required: true };
    }
  
    if (this.showDay && (this.selectedMonth == null || this.selectedDay == null)) {
      return { required: true };
    }

    if(this.showMonth==false && this.showDay==false) {
      this.selectedMonth =1;
      this.selectedDay=1;
    } else if(this.showDay==false && this.showMonth==true) {

      this.selectedDay=1;
    }

    console.log(this.selectedYear+','+ (this.selectedMonth - 1)+','+this.selectedDay)
    const selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);
 
      // Check if the selectedDate is a valid Date
    if (isNaN(selectedDate.getTime())) {
        // Invalid date
        return { invalidDate: true };
    }

    const dateString = moment(selectedDate).format('YYYY-MM-DD');
  

    this.onChange(dateString);
    this.onTouched();
  }
 
  writeValue(value: any): void {
    if (value) {
      const date = new Date(value);
      this.selectedYear = date.getFullYear();
      this.selectedMonth = date.getMonth() + 1;
      this.selectedDay = date.getDate();
    } else {
      this.selectedYear = null;
      this.selectedMonth = null;
      this.selectedDay = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}


/*
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'ng-birthdate',
  templateUrl: './ng-birthdate.template.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgBirthDateComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NgBirthDateComponent),
      multi: true
    }
  ]
})
export class NgBirthDateComponent implements ControlValueAccessor, Validator, OnInit {

  years: string[];
  months: string[];
  days: string[];

  selectedYear: number;
  selectedMonth: number;
  selectedDay: number;
   
  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() showMonth: boolean = true;
  @Input() showDay: boolean = true;


  constructor() {
    const currentYear = new Date().getFullYear() + 543;
    this.years = this.generateRange( 2470,currentYear).reverse();
    //console.log(this.years)
    this.months = [
      'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
      'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
    ];
    this.days = this.generateRange(1, 31);
  }

  ngOnInit(): void {
    this.validate();
    
  }

  generateRange(start: number, end: number): string[] {
    return Array.from({ length: end - start + 1 }, (_, index) => (start + index).toString());
  }

 
   
  writeValue(value: any): void {
    if (value) {
      const date = new Date(value);
      this.selectedYear = date.getFullYear();
      this.selectedMonth = date.getMonth() + 1;
      this.selectedDay = date.getDate();
    } else {
      this.selectedYear = null;
      this.selectedMonth = null;
      this.selectedDay = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): { [key: string]: any } {
    if (this.selectedYear == null) {
      return { required: true };
    }
  
    if (this.showMonth && this.selectedMonth == null) {
      return { required: true };
    }
  
    if (this.showDay && (this.selectedMonth == null || this.selectedDay == null)) {
      return { required: true };
    }
  
    const selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, this.selectedDay);
  
    
  
  
    const dateString = moment(selectedDate).format('YYYY-MM-DD');
  
    return { dateString };
  }
  
}

*/