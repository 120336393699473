import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'main-menu-form',
  templateUrl: './main-menu-form.component.html'
})

export class MainMenuFormComponent  implements OnInit{
    loading = false;
    submitted = false;
    routeParams = null;
    obj: any = {};
    permission: any = {};
    id = 0;
    constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private router: Router,
      private activeRoute: ActivatedRoute,
      private service: DataService,
      private authService: AuthService
    ) {
         
    }
 
  async ngOnInit(): Promise<void> {
      // adding the lifecycle hook ngOnInit
      //this.service.get('users/list').subscribe(data => {
      //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
      ///});
      
      this.routeParams = this.activeRoute.snapshot.params;
      if (this.routeParams.id != null) this.getById(this.routeParams.id);
      this.permission =   this.authService.getPermission(this.router.url);
    }
    getById(id: any) {
      this.service.get("menu/getmainmenu/"+id)
        .subscribe(response => {
          this.obj = response;
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }

  
    onSubmit() {
      //this.submitted = true;
    
      this.service.post("menu/savemainmenu", this.obj)
        .subscribe((response: any) => {
          
          this.toastr.success("Create or Update Completed.", 'Succcess');
          this.router.navigate(['/admin/mainmenu']);
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    
    }
}
