import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgTableComponent } from 'src/app/components/forms/ng-table.component';
import { NgTableColumn, NgTableFormat } from 'src/app/components/forms/ng-table.inferface';
@Component({
  selector: 'app-personal-component',
  templateUrl: './personal.component.html'
})

export class PersonalComponent implements OnInit {

  request: DataService;
  permission: any = {};
  userInfo: any = {};

  url:string;
  @ViewChild(NgTableComponent) table: NgTableComponent;
  
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient,
    private service: DataService,
    private authService: AuthService
  ) {
    
  }

 

  async ngOnInit(): Promise<void> {
 
    this.userInfo = await this.authService.getUser();
    //this.permission = await this.authService.getPermission("personal");
  }

 

  public datasource = (params: any): Observable<any> => {    
    return this.service.post(`personal/list`,params);
  };

 
  onDelete(row: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("personal/delete", row.id)
        .subscribe(response => {
          this.table.refresh()
          this.toastr.success("Delete completed.", 'Success.');
          
        },
          error => {
            this.toastr.error(error.error.message, 'Error!');
          });
    }
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  genLink() {
    this.service.get("personal/genlink")
        .subscribe((response:any) => {
            //console.log(response)
            this.toastr.success("copy link to clipboard completed.", 'Success.');      
            this.url=response.url;
            this.copyToClipboard(this.url)
        },  error => {
            this.toastr.error(error.error.message, 'Error!');
          });
  }

 
  
  onDownload(row: any) {
      console.log(row)
      this.http.get('/api/personal/print/'+row.guID, { responseType: 'blob' })
        .subscribe((response: Blob) => {
          const downloadLink = document.createElement('a');
          const fileURL = URL.createObjectURL(response);
          downloadLink.href = fileURL;
          downloadLink.download = 'document.pdf';
          downloadLink.click();
          URL.revokeObjectURL(fileURL);
        });
  }
  /*
  downloadPdf2(data: any) {
    return this.service.post("api/personal/getopenaccountfile",data, { responseType: 'blob' })
      .toPromise();
  }
  */
  onRefresh(data: any) {
    
   this.service.post("personal/verifyidcard", data)
      .subscribe(data => {
        this.table.refresh()
        this.toastr.success("Recall api completed.", 'Success.');      
    });
  }
  show(m:any) {
    console.log(m)
    this.toastr.error(m,"Error");  
  }
  success(m:any) {
    
    this.toastr.success(m,"Success");  
  }
  onExport() {
  
    this.http.get(`api/personal/export`, { responseType: 'blob' })
    .subscribe((blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'personal.xlsx';
        link.click();
      });
  }
  /*
  downloadPdf(data: any) {
    return this.service.get("api/personal/print/"+data, { responseType: 'blob' })
      .toPromise();
  }

  messageDOPA(id: any) {
    var data = this.datasource.filter(obj => obj.id == id);
    alert(data.dopaResponse);
  }
  */
  
  public columns: Array<NgTableColumn> = [
    { title: 'PostDate', name: 'postDate',width:"130px", sort: true ,format:NgTableFormat.DateTime},        
    { title: 'IDCard.', name: 'idCardNo',width:"150px", sort: true,format:NgTableFormat.Masking },
    { title: 'FirstName', name: 'firstNameTH', sort: true },
    { title: 'LastName', name: 'lastNameTH', sort: true },
    { title: 'Staff', name: 'fullName', sort: true},       
    { title: 'Dopa',template: 'dopa', name: 'dopa',align:'center', sort: true,width:"80px" },
    { title: 'FREEZE-05',template: 'amlo1', name: 'freeze05',align:'center', sort: true ,width:"100px"},    
    { title: 'HR-02',template: 'amlo2', name: 'hr02',align:'center', sort: true ,width:"80px"},    
    { title: 'HR-08-RISK',template: 'amlo3', name: 'hr08Risk',align:'center', sort: true ,width:"120px"},    
    { template: 'action',width:"120px",sort: false ,},     
  ];
} 