import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
 
import {BasicLayoutComponent} from "./basicLayout.component";
import {BlankLayoutComponent} from "./blankLayout.component";
import {TopBarComponent} from "./top-bar.component";
import {FooterBarComponent } from "./footer-bar.component";
import {NavBarComponent } from "./nav-bar.component";
import { MobileLayoutComponent } from "./mobile-layout.component";

@NgModule({
  declarations: [
    FooterBarComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    MobileLayoutComponent,
    NavBarComponent,
    TopBarComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
  ],
  exports: [
    FooterBarComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    MobileLayoutComponent,
    NavBarComponent,
    TopBarComponent
  ],
})

export class LayoutsModule {}
