 

<div class="loginColumns animated fadeInDown center"  style="max-width: 500px;">
  <div class="row">

    
    <div class="col-md-12">
      <div class="ibox-content rounded shadow-sm">
        <h3 class="mb-20 text-upper">eAccount System</h3>
        <hr>
        <form name="form" class="m-t" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate autocomplete="off">
          <div class="form-group">
            <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required placeholder="UserName" />
            <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required placeholder="Password" />
            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
          </div>

          <button  (ngClick)="login()" class="btn btn-primary block full-width m-b">Login</button>
          <a href="#">
            <small>Forgot password?</small>
          </a>

      
        </form>
        <p class="m-t">
          <small>Oneable © 2023</small>
        </p>
      </div>
    </div>
  </div>
  
</div>
