import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { Location } from "@angular/common";
declare var $: any;

@Component({
  selector: 'nav-bar',
  templateUrl: 'nav-bar.template.html'
})

export class NavBarComponent implements  AfterViewInit {
 
  @ViewChild('menu', ) menuElement: ElementRef;

  public userInfo;
  public menuList;
  public perminnsion;
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private location: Location
  ) {
    this.userInfo = authService.getUser();
    this.menuList = authService.getMenu();
  }

  ngAfterViewInit() {
     
    $(this.menuElement.nativeElement).metisMenu();
    //console.log($('#side-menu').html())
    if ($("body").hasClass('fixed-sidebar')) {
      $('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }
  activeRouteMain(routename: string): boolean {

    //console.log(routename+','+this.location.path())
    var a = this.location.path();
    var b = this.location.path().indexOf("/"+routename);
    //if (b > -1) {
     // console.log(routename);
    //}
    return this.router.url.indexOf("/"+routename) > -1;
  }
  activeRouteSub(path: string): boolean {
    //return this.router.url.indexOf(routename) > -1;
    var a = this.location.path();
    var b = this.location.path().indexOf(path);
    //if (b == 0) {
    //  console.log(path);
    //}
    return this.location.path().indexOf(path) > -1;

  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }  

}
