
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h3>User List</h3>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" [style.display]="permission.isRead == true ? 'block' : 'none'">
              <ng-table 
              #table
              [columns]="columns" 
              [datasource]="datasource" 
              [allowCheckbox]="false"
              [defaultSortColumn]="'id'"              
              (checkedItemsChange)="onCheckedItemsChange($event)"
              >
                <ng-template #action   let-item="row">
                  <button class="btn btn-success btn-xs" (click)="onEdit(item)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-danger btn-xs"(click)="onDelete(item)"><i class="fa fa-trash"></i></button>
                </ng-template>    
                <ng-template #status let-item="row">               
                  <i class="fa fa-check text-success" style="font-size:16px"  *ngIf="item.isActive==1"></i>
                  <i class="fa fa-times text-danger" style="font-size:16px"  *ngIf="item.isActive==0"></i>
                </ng-template> 
                <ng-template #locked let-item="row">               
                  <i class="fa fa-check text-success" style="font-size:16px"  *ngIf="item.lockoutEnabled==true"></i>
                  <i class="fa fa-times text-danger" style="font-size:16px"  *ngIf="item.lockoutEnabled==false"></i>
                </ng-template> 
              </ng-table>
            </div>
            <div class="ibox-footer">
              <a class="btn btn-success" [routerLink]="['/admin/users/create']" [style.display]="permission.isAdd == true ? 'inline-block' : 'none'">AddNew</a>
            </div>
          </div>        
    </div>
  </div>
</div>
