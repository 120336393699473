
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>General</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" >
              <div class="row">
                <div class="col-lg-6"> 
                    <div class="form-group row">
                      <label for="code" class="col-lg-3 col-form-label">Type</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control" name="typeCode" [(ngModel)]="obj.typeCode" #typeCode="ngModel" [ngClass]="{ 'is-invalid': f.submitted && typeCode.invalid }" required />
                        <div *ngIf="f.submitted && code.invalid" class="invalid-feedback">
                          <div *ngIf="typeCode.errors.required">Type is required</div>
                        </div>
                      </div>
                    </div>
                      <div class="form-group row">
                        <label for="code" class="col-lg-3 col-form-label">Code</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="code" [(ngModel)]="obj.code" #code="ngModel" [ngClass]="{ 'is-invalid': f.submitted && code.invalid }" required />
                          <div *ngIf="f.submitted && code.invalid" class="invalid-feedback">
                            <div *ngIf="code.errors.required">Code is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="name" class="col-lg-3 col-form-label">Name</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="name" [(ngModel)]="obj.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                          <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="refCode" class="col-lg-3 col-form-label">Ref Code</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="refCode" [(ngModel)]="obj.refCode" #refCode="ngModel"     />
                          
                        </div>
                      </div>
                      
                      <div class="form-group row">
                        <label for="description" class="col-lg-3 col-form-label">Description</label>
                        <div class="col-lg-9">
                          <textarea type="text" class="form-control" name="description" [(ngModel)]="obj.description" #description="ngModel"   ></textarea>
                          
                        </div>
                      </div>
             
                    
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>                     
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/setup/general']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>  
  </form> 
</div>
