import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/services.loading';
 
declare var jQuery:any;

@Component({
  selector: 'blank',
  templateUrl: 'blankLayout.template.html'
})
export class BlankLayoutComponent implements OnInit {
  loading: boolean = false;
  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef) {
   
  }
   

  ngOnInit() {
    setTimeout(() => {
      this.loaderService.isLoading.subscribe((v) => {
        this.loading = v;
        // Manually trigger change detection cycle
        this.cdr.detectChanges();
      });
    });
  }


  ngAfterViewInit() {
    jQuery('body').addClass('gray-bg');
  }

  ngOnDestroy() {
    jQuery('body').removeClass('gray-bg');
  }
}
