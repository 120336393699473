import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';

import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
 
import { NgModalComponent } from 'src/app/components/forms/ng-modal.component';
import { BankAccountFormComponent } from './bankaccount-form.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'account-form',
  templateUrl: './account-form.component.html'
})

export class AccountFormComponent implements OnInit {
  @ViewChild('modalRef') modalRef: NgModalComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  loading = false;
  submitted = false;
  routeParams = null;
  obj: any = {
    countryIncomeId:203,
    cuntryId:203
  };
  id = 0;
  riskLevelList= [1,2,3,4,5,6,7,8];
  selectedFile: File | null = null;
  documentTypeId:null;
  hasSpouse:boolean=false;
  data= {
    statusList:[],
    titleList:[],
    countryList:[],
    cardTypeList:[],
    maritalStatusList:[],
    investmentObjectiveList:[],
    sourceOfIncomeList:[],
    incomeList:[],
    occupationList:[],
    businessList:[],
    mailingMethodList:[],
    mailingAddressList:[],
    relationshipList:[],
    subDistrictList:[],
    provinceList:[],
    districtList:[],
    contactDistrictList:[],
    contactSubDistrictList:[],
    mailingDistrictList:[],
    mailingSubDistrictList:[],
    officeDistrictList:[],
    officeSubDistrictList:[],
    investorContactList:[],
    documentTypeList:[],
    bankaccountList:[],
    attachmentList:[],
    accountTypeList:[],
  };

 
  permission: any = {};
  path = "";
  generalList = [];
  bankList=[];
  bankaccount:any = {};
  
  selectedValues = ['1', '3'];
  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private service: DataService,
    private authService: AuthService,
    public activeModal: NgbActiveModal,
  ) {
     
  }

   ngOnInit(){
    // adding the lifecycle hook ngOnInit
    //this.service.get('users/list').subscribe(data => {
    //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
    ///});

    this.routeParams = this.activeRoute.snapshot.params; 
    this.path = this.activeRoute.routeConfig.path.split("/")[0];
    this.getData()
    
    if(this.routeParams.id != null) this.getRow(this.routeParams.id) ;
  }

  getRow(id: any) {
    this.service.get("account/get/"+id)
      .subscribe((response: any) => {
        this.obj = response.data;
        if(this.obj.investmentObjective!=null) this.obj.investmentObjective =this.obj.investmentObjective.split(',');
        if(this.obj.sourceOfIncome!=null) this.obj.sourceOfIncome =this.obj.sourceOfIncome.split(',')
        if(this.obj.accountType!=null) this.obj.accountType =this.obj.accountType.split(',')
        this.data.districtList=response.districts;
        this.data.subDistrictList=response.subdistricts;
        this.data.contactDistrictList=response.contactdistricts;
        this.data.contactSubDistrictList=response.contactsubdistricts;
        this.data.officeDistrictList=response.officedistricts;
        this.data.officeSubDistrictList=response.officesubdistricts;

        this.data.mailingDistrictList=response.mailingdistricts;
        this.data.mailingSubDistrictList=response.mailingsubdistricts;

        this.data.bankaccountList=response.bankaccouts;  
        this.data.attachmentList=response.attachments;
        this.changeMaritalstatus(this.obj.maritalStatusId)

        console.log(this.data)
        //this.obj.position = response.position;
      },  error => {
          this.toastr.error(error.error.message, 'Error!');
     });
  }
  showModal: boolean = false;
  modalInput: string;

  getURL(obj:any) {
    //console.log(obj)
    return environment.baseUrl+"/Uploads/Attachment/"+obj.id+"/"+obj.fileName;
  }
  openModal() {
    this.modalRef.show(BankAccountFormComponent,{accountId:this.obj.id});
  }
  hideModal() {
    this.modalRef.hide();
  } 
  onEditBankAccount(obj) {
    this.modalRef.show(BankAccountFormComponent,obj);
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  
    if (!this.selectedFile) {
      //console.log('No file selected');
      this.toastr.error("No file selected.", 'error.');
      return;
    }
    if (!this.documentTypeId) {
      //console.log('No file selected');
      this.toastr.error("Documenet type not selected.", 'error.');
      return;
    }
    var jsonData ={
      accountId:this.obj.id,
      documentTypeId:this.documentTypeId
    }
    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    formData.append('jsonData', JSON.stringify(jsonData)); // Add JSON data to form data
 
    this.service.upload("attachment/upload",this.selectedFile,jsonData)
    .subscribe(response => {
      this.toastr.success("upload completed.", 'Success.');
      this.resetUpload();
      this.getRow(this.obj.id)
    },error => {
        this.toastr.error(error.error.message, 'Error!');
    });
  
  }
  resetUpload() {
    this.selectedFile=null;
    this.documentTypeId=null;
    this.fileInput.nativeElement.value = '';
  }
  onDeleteBankAccount(row: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("bankaccount/delete", row.id)
        .subscribe(response => {
          this.toastr.success("Delete completed.", 'Success.');
          this.getRow(this.obj.id)
        },error => {
            this.toastr.error(error.error.message, 'Error!');
        });
    }
  }
  onDeleteFile(row: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("attachment/delete", row.fileName)
        .subscribe(response => {
          this.toastr.success("Delete completed.", 'Success.');
          this.getRow(this.obj.id)
        },error => {
            this.toastr.error(error.error.message, 'Error!');
        });
    }
  }
  onDownloadFile(row: any) {
    console.log(row)
    this.service.download('attachment/download/'+row.fileName)
      .subscribe((response: Blob) => {
        const downloadLink = document.createElement('a');
        const fileURL = URL.createObjectURL(response);
        downloadLink.href = fileURL;
        downloadLink.download = row.fileName;
        downloadLink.click();
        URL.revokeObjectURL(fileURL);
      },error => {
        console.log(error.error)
          this.toastr.error("File not found.", 'Error!');
      });
}
  onCompletionModal(data) {
    //console.log(data)
    //console.log('success')
    if(data) {
      this.getRow(this.obj.id),
      console.log('success')
    }
  }

  changeStatus() {

    if(confirm("Are you sure approve")) {
     
     this.service.get("account/approve/"+this.obj.id)
     .subscribe((response:any) => {
       this.toastr.success(response.message, 'Success.');
       this.router.navigate(['/account/eoacc']);
     },
       error => {
         console.log(error)
         this.toastr.error(error.error.message, 'Error!');
       });

    }
 }

  getData() {
    this.service.get("account/getdata")
      .subscribe((response: any) => {
        this.data = response;
        //this.obj.position = response.position;
      },  error => {
          this.toastr.error(error.error.message, 'Error!');
     });
  }

 

  changeMaritalstatus(e) {
    var obj= this.data.maritalStatusList.find((o:any)=>o.id==e);
    console.log(obj)
    this.hasSpouse=(obj.id==30)//สมรส
    
  }
  changeProvince(e) {
    //console.log(e)
    this.service.get("province/getdistrict/"+e)
    .subscribe((response: any) => {
          this.data.districtList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeDistrict(e) {
    this.service.get("province/getsubdistrict/"+e)
    .subscribe((response: any) => {
          this.data.subDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeSubDistrict(e) {
    //console.log(e)
    var obj=this.data.subDistrictList.find((o:any)=>o.id==e)
    console.log(obj)
    this.obj.zipCode=obj.zipcode;
  }

  changeProvince2(e) {
    //console.log(e)
    this.service.get("province/getdistrict/"+e)
    .subscribe((response: any) => {
          this.data.contactDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeDistrict2(e) {
    this.service.get("province/getsubdistrict/"+e)
    .subscribe((response: any) => {
          this.data.contactSubDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeSubDistrict2(e) {
    //console.log(e)
    var obj=this.data.contactSubDistrictList.find((o:any)=>o.id==e)
    //console.log(obj)
    this.obj.contactZipCode=obj.zipcode;
  }
  changeProvince3(e) {
    //console.log(e)
    this.service.get("province/getdistrict/"+e)
    .subscribe((response: any) => {
          this.data.officeDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeDistrict3(e) {
    this.service.get("province/getsubdistrict/"+e)
    .subscribe((response: any) => {
          this.data.officeSubDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeSubDistrict3(e) {
    //console.log(e)
    var obj=this.data.officeSubDistrictList.find((o:any)=>o.id==e)
    //console.log(obj)
    this.obj.officeZipCode=obj.zipcode;
  }

  changeProvince4(e) {
    //console.log(e)
    this.service.get("province/getdistrict/"+e)
    .subscribe((response: any) => {
          this.data.mailingDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeDistrict4(e) {
    this.service.get("province/getsubdistrict/"+e)
    .subscribe((response: any) => {
          this.data.mailingSubDistrictList = response;       
        },error => {
          this.toastr.error(error.error.message, 'Error!');
    });
  }
  changeSubDistrict4(e) {
    //console.log(e)
    var obj=this.data.mailingSubDistrictList.find((o:any)=>o.id==e)
    //console.log(obj)
    this.obj.mailingZipCode=obj.zipcode;
  }


  onSubmit() {
    if(this.obj.investmentObjective!=null && this.obj.investmentObjective.length>0) this.obj.investmentObjective =this.obj.investmentObjective.join(',');
    if(this.obj.sourceOfIncome!=null && this.obj.sourceOfIncome.length>0) this.obj.sourceOfIncome =this.obj.sourceOfIncome.join(',')
    if(this.obj.accountType!=null && this.obj.accountType.length>0) this.obj.accountType =this.obj.accountType.join(',')

    this.service.post('account/post', this.obj)
      .subscribe((response: any) => {
        //console.log(response.data);
        
        this.toastr.success('Create or Update Completed.', 'Succcess');
        this.router.navigate(['/account/eoacc/editing/'+response.id]);
      },
      error => {
        console.log(error)
        this.toastr.error(error.error.message, 'Error');
      });
  }
}
