 import { Injectable, Inject, Injector } from '@angular/core';
import { HttpRequest, HttpHandler,HttpResponse, HttpEvent,HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { Router, RouterStateSnapshot } from "@angular/router"
import { LoaderService } from '../services/services.loading';
//import { LoaderService } from '../components/Loader/loader.service';
//import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    public userService;
    constructor(
      private router: Router,
      private injector: Injector,
      private loaderService: LoaderService,
      private authService: AuthService) {

    }
    private generateAuthHeaders = function (req) {
        return req.clone({
            setHeaders: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${  this.authService.getToken() }`
            }                      
        });
    };

    private generateAuthHeadersForUploadFiile = function (req) {
        return req.clone({
          setHeaders: {
            //'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.authService.getToken()}`,
          }
        });
    };
    removeRequest(req: HttpRequest<any>) {
      const i = this.requests.indexOf(req);
      if (i >= 0) {
        this.requests.splice(i, 1);
      }
      this.loaderService.isLoading.next(this.requests.length > 0);
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      if (req.url.includes('upload')) {
        console.log('upload')
        req = this.generateAuthHeadersForUploadFiile(req);
      } else {
        req = this.generateAuthHeaders(req);
      }
      this.requests.push(req);
      this.authService = this.injector.get(AuthService);
      console.log("No of requests--->" + this.requests.length);
  
      this.loaderService.isLoading.next(true);
      return Observable.create(observer => {
        const subscription = next.handle(req)
          .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                this.removeRequest(req);
                observer.next(event);
              }
            },
            err => {
              //alert('error' + err);
              this.removeRequest(req);
              if (err.status === 401) {
                console.log("renew access token");
                this.authService.logout();
                this.router.navigate(['/login']);
                /*
                return this.authService.refreshToken().subscribe(
                  response => {
                    localStorage.removeItem('token');
                    localStorage.setItem('token', response['access_token']);
                    localStorage.setItem('refresh_token', response['refresh_token']);
                    req = this.generateAuthHeaders(req);
                    return next.handle(req);
                  },
                  error => {
                    this.authService.logout();
                    this.router.navigate(['/login']);
                    return  observer.error(err);
                  }
                );
                 */
              }
                
             
              observer.error(err);
            },
            () => {
              this.removeRequest(req);
              observer.complete();
            });
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    }
}
