import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {NgSelectComponent} from "./ng-select.component";
import {NgDatePickerComponent} from "./ng-datepicker.component";
import { FormsModule } from "@angular/forms";
import { NgTableComponent } from "./ng-table.component";
import { NgSqlEditorComponent } from "./ng-sql-editor.compoent";
import { NgBirthDateComponent } from "./ng-birthdate.component";
import { NgModalComponent } from "./ng-modal.component";
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgCheckBoxComponent } from "./ng-checkbox.component";

@NgModule({
  declarations: [
    NgSelectComponent,
    NgDatePickerComponent,
    NgTableComponent,
    NgSqlEditorComponent,
    NgBirthDateComponent,
    NgModalComponent,
    NgCheckBoxComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule, 
    NgbModalModule
  ],
  exports: [
    NgSelectComponent,
    NgDatePickerComponent,
    NgTableComponent,
    NgSqlEditorComponent ,
    NgBirthDateComponent,
    NgModalComponent,
    NgCheckBoxComponent
  ],
})

export class NgFormModule {}
