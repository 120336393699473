
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>ข้อมูลบัตรประชาชน</h5>
          <!--<iboxtools></iboxtools>-->
        </div>
        <div class="ibox-content">
           <ng-table 
            #table
            [columns]="columns" 
            [datasource]="datasource" 
            [allowFilter]="true"
            [allowCheckbox]="false"
            [defaultSortDirection]="'desc'"
            [defaultSortColumn]="'postDate'">
              <ng-template #action   let-item="row">
                <button class="btn btn-success btn-xs" (click)="onDownload(item)" [disabled]="false"><i class="fa fa-file-pdf-o"></i> </button>              
                <button class="btn btn-info btn-xs"(click)="onRefresh(item)" [disabled]="false"><i class="fa fa-refresh" ></i> </button>
                <button class="btn btn-danger btn-xs" (click)="onDelete(item)"><i class="fa fa-trash"></i> </button>
              </ng-template> 
              <ng-template #dopa   let-item="row">
                 <i class="fa fa-check-circle text-success" *ngIf="item.dopa=='Y'" (click)="success(item.dopa)" style="cursor: pointer;"></i>
                 <i class="fa fa-circle text-danger" *ngIf="item.dopa!='Y'"  (click)="show(item.dopaResponse)" style="cursor: pointer;">  </i>
              </ng-template>
                <ng-template #amlo1   let-item="row">
                <i class="fa fa-check-circle text-success" *ngIf="item.freeze05=='N'" (click)="success(item.amloResponse)" style="cursor: pointer;"></i>
                <i class="fa fa-circle text-danger" *ngIf="item.freeze05!='N'"  (click)="show(item.amloResponse)" style="cursor: pointer;"></i>
              </ng-template>
              <ng-template #amlo2   let-item="row">
                <i class="fa fa-check-circle text-success" *ngIf="item.hr02=='N'" (click)="success(item.amloResponse)" style="cursor: pointer;"></i>
                <i class="fa fa-circle text-danger" *ngIf="item.hr02!='N'"  (click)="show(item.amloResponse)" style="cursor: pointer;"></i>
              </ng-template>
              <ng-template #amlo3   let-item="row">
                <i class="fa fa-check-circle text-success" *ngIf="item.hr08Risk=='N'" (click)="success(item.amloResponse)" style="cursor: pointer;"></i>
                <i class="fa fa-circle text-danger" *ngIf="item.hr08Risk!='N'"  (click)="show(item.amloResponse)" style="cursor: pointer;"></i>
              </ng-template>    
            </ng-table>
            <hr>
            <!--<div class="row">
              <div class="col-lg-6 mb-20">
                  <button class="btn btn-primary" (click)="genLink()" type="button" style="display:inline;width: 15%;">Get Link</button>
                  <input type="text" class="form-control" [(ngModel)]="url" readonly style="display:inline;width: 80%;"> 
             </div>
           </div>-->

            <p class="text-muted">
                     
              FREEZE-05 :รายชื่อบุคคลที่ศาลมีคาสั่งเป็นบุคคลที่ถูกกาหนด (Thailand list),<br>
              HR-02 :รายชื่อบุคคลที่ถูกยับยั้งการทาธุรกรรม,<br>
              HR-08-RISK : รายชื่อบุคคลที่มีความเสี่ยงสูงต่อการฟอกเงิน<br>
                  
            </p>
        </div>
        <div class="ibox-footer">
          <button class="btn btn-primary " (click)="onExport()"><i class="fa fa-file-excel-o"></i> Export</button>
        </div>
      
      </div>
<!--  FREEZE-04 :รายชื่อบุคคล คณะบุคคล นิติบุคคล หรือองค์กรซึ่งเป็นผู้กระทาอันเป็นการก่อการร้าย (UN list)-->
     
    </div>
  </div>
  
</div>
