import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
 
import { HttpClient } from '@angular/common/http';
 
import { Router } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgTableComponent } from 'src/app/components/forms/ng-table.component';
import { NgTableColumn, NgTableFormat } from 'src/app/components/forms/ng-table.inferface';
 
@Component({
  selector: 'app-account-component',
  templateUrl: './account.component.html'
})

export class AccountComponent implements OnInit {

  request: DataService;
  permission: any = {};
  @ViewChild(NgTableComponent) table: NgTableComponent;
  
  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private service: DataService,
    private authService: AuthService
  ) {
   ;
  }
 
  async ngOnInit(): Promise<void> {
    // adding the lifecycle hook ngOnInit
    //this.service.get('users/list').subscribe(data => {
    //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
    ///});
    //this.permission = await this.authService.getPermission("list");
    //this.getData()
  }
  
  

  public datasource = (params: any): Observable<any> => {    
    return this.service.post(`account/list`,params);
  };

  onDelete(row: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("account/delete", row.id)
        .subscribe(response => {
          this.toastr.success("Delete completed.", 'Success.');
          this.table.refresh()
        },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
    }
  }

  onLoad() {
    this.service.get("account/load")
      .subscribe((response:any) => {
        this.toastr.success(response.message, 'Success.');
        this.table.refresh()
      },
        error => {
          console.log(error)
          this.toastr.error(error.error.message, 'Error!');
        });
  }

  downloadPDF(appicationId:any) {

    this.http.get(`api/account/downloadPDF/${appicationId}`, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = appicationId+".pdf";
        link.click();
        window.URL.revokeObjectURL(url);
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
 

     
  }

 

  onExport() {
  
    this.http.get(`api/account/export`, { responseType: 'blob' })
    .subscribe((blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'eaccounts.xlsx';
        link.click();
      });
  }
  
 
  onEdit(e) {
    this.router.navigate(['/account/eoacc/editing/'+e.id]);
  }
  
  public columns: Array<NgTableColumn> = [
    { title: 'AccountType', name: 'accountType', sort: true },
    { title: 'ApplicationId', name: 'applicationId', sort: true},
    { title: 'FirstName', name: 'firstName', sort: true },
    { title: 'LastName', name: 'lastName', sort: true },
    { title: 'CardNumber', name: 'cardNumber', sort: true },
    { template: 'status',title: 'Status', name: 'status', sort: true },   
    { title: 'NdidStatus', name: 'ndidStatus', sort: true },
    { title: 'SubmittedTime', name: 'submittedTime', sort: true },
    { template: 'action',width: '10%',sort: false,},
  ];
}
