import { Component, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from 'src/app/shared/services/services.loading';
 
declare var jQuery:any;

@Component({
  selector: 'basic',
  templateUrl: 'basicLayout.template.html',
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class BasicLayoutComponent implements OnInit {
  public loading: boolean = false;
  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef) {
   
  }
  
  ngOnInit() {
    setTimeout(() => {
      this.loaderService.isLoading.subscribe((v) => {
        this.loading = v;
        // Manually trigger change detection cycle
        this.cdr.detectChanges();
      });
    });
  }

   
 
  public onResize(){
    //detectBody();
  }

}
