<div class="wrapper wrapper-content  animated fadeInRight">
    <div class="row">
      <!-- Query panel -->
      <div class="col-md-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">SQL Analyzer</h3>
          </div>
          <div class="panel-body "  >
              <form (ngSubmit)="executeQuery()">
                <div class="form-group ">      
                  <div class="row">
                    <div class=" col-md-2">
                       <ng-select [options]="tables"  [optionLabel]="'tableName'" [optionValue]="'tableName'"  (ngModelChange)="onChange($event)" name="tableName" [(ngModel)]="tableName"   ></ng-select>   
                    </div>
                  </div>          
                   
                </div>
                <div class="form-group ">
                  <textarea class="form-control" id="query" name="query" [(ngModel)]="query"></textarea>
                </div>
              
                <button type="submit" class="btn btn-primary mb-20" [disabled]="query.length==0">Execute Query</button>
              </form>
  
              
              <ng-table 
              #table
              [columns]="columns" 
              [datasource]="datasource" 
              [allowFilter]="false"
              [allowCheckbox]="false"
              [allowPaging]="false"
              [defaultSortColumn]="'id'">
              </ng-table>
            
          </div>
        </div>
      </div>
  
      <!-- Result table -->
      <div class="col-md-8 col-lg-9">
        
      </div>
    </div>
  </div>
  