
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>e-OpenAccount</h5>
            </div>
            <div class="ibox-content">
              <ng-table 
              #table
              [columns]="columns" 
              [datasource]="datasource" 
              [allowFilter]="true"
              [allowCheckbox]="false"
              [defaultSortColumn]="'id'"              
             
              >
                <ng-template #action   let-item="row">
                 
                  <button class="btn btn-success btn-xs" (click)="onEdit(item)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-danger btn-xs"(click)="onDelete(item)"><i class="fa fa-trash"></i></button>
                </ng-template>    
                <ng-template #status   let-item="row">
                 
                  <label class="label bg-secondary  text-white" *ngIf="item.status=='SUBMITTED'"> {{item.status}}</label>
                  <label class="label bg-primary" *ngIf="item.status=='APPROVED'"> {{item.status}}</label>
                </ng-template>    
              </ng-table>
            </div>
            <div class="ibox-footer">
              <a class="btn btn-success" [routerLink]="['/account/eoacc/create']"><i class="fa fa-plus"></i> AddNew</a>
              <a class="btn btn-info" (click)="onLoad()"><i class="fa fa-refresh"></i> Load</a>
              <button class="btn btn-secondary " (click)="onExport()"><i class="fa fa-file-excel-o"></i> Export</button>
            </div>
          
          </div>        
    </div>   
</div>
