import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'country-form',
    templateUrl: './country-form.component.html'
})

export class CountryFormComponent  implements OnInit{
 
    submitted = false;
    routeParams = null;
    obj: any = {isActive:1};
    roleslist = [];
    permission: any = {};
    id = 0;
    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private service: DataService,
        private authService: AuthService
    ) {
       
    }
 
    ngOnInit() {
      this.routeParams = this.activeRoute.snapshot.params;
      if(this.routeParams.id != null) this.getRow(this.routeParams.id) ;
      //this.permission = await this.authService.getPermission("country");
      this.permission =   this.authService.getPermission(this.router.url);
 
    }
 
    getRow(id: any) {
      this.service.get("country/get/"+id)
        .subscribe(response => {
          this.obj = response;
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
     
    onSubmit() {
 
      this.service.post("country/post", this.obj)
        .subscribe((response: any) => {
          //this.obj = response;
          this.toastr.success("Create or Update Completed.", 'Succcess');
          this.router.navigate(['/setup/country']);
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
        });
    
    }
}
