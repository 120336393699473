
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>รายการเมนูหลัก</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" [style.display]="permission.isRead == true ? 'block' : 'none'">
              <ng-table 
              #table
              [columns]="columns" 
              [datasource]="datasource"       
              [defaultSortColumn]="'id'" >
                <ng-template #action   let-item="row">
                  <button class="btn btn-success btn-xs" (click)="onEdit(item)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-danger btn-xs"(click)="onDelete(item)"><i class="fa fa-trash"></i></button>
                </ng-template>                                      
              </ng-table>             
            </div>   
            <div class="ibox-footer">
              <a class="btn btn-success" [routerLink]="['/admin/mainmenu/create']" [style.display]="permission.isAdd == true ? 'inline-block' : 'none'">AddNew</a>
            </div>     
    </div>
  </div>
</div>
