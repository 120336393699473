import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'ng-sql-editor',
  templateUrl: './ng-sql-editor.component.html',
  styleUrls: ['./ng-sql-editor.component.css']
})
export class NgSqlEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  private editor: any;

  ngOnInit(): void {
    this.editor = $('#ng-sql-editor');
  }

  ngAfterViewInit(): void {
    this.editor.val(this.highlightKeywords(this.editor.val()));
    this.editor.on('input', () => {
      this.editor.val(this.highlightKeywords(this.editor.val()));
    });
    this.editor.css('font-family', 'monospace');
    this.editor.css('font-size', '14px');
    this.editor.css('line-height', '1.5em');
    this.editor.css('padding', '10px');
    this.editor.css('border', '1px solid #ccc');
  }

  ngOnDestroy(): void {
    // Clean up the CodeMirror instance
    this.editor.codemirror.toTextArea();
  }

  highlightKeywords(sql: string): string {
    const keywords = /\b(SELECT|FROM|WHERE|AND|OR|NOT|ORDER BY|GROUP BY|HAVING|IN|LIKE|BETWEEN|CASE|WHEN|THEN|ELSE|END)\b/gi;
    return sql.replace(keywords, '<span class="ng-sql-editor-keyword">$1</span>');
  }
}
