<div class="loginColumns animated fadeInDown " style="max-width: 550px;margin-top: 200px;">
  <div class="row"> 
    <div class="col-md-12">
      <div class="ibox rounded">
        <div class="ibox-title">
          <h5>เปลี่ยนรหัสผ่าน</h5>
          <!--<iboxtools></iboxtools>-->
        </div>
        <div class="ibox-content ">
          <form name="form"   class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <div class="form-group row">
              <label for="password" class="col-lg-4 control-label">รหัสผ่านปัจจุบัน</label>
              <div class="col-lg-8">
                <input type="password" class="form-control" name="currentPassword" autocomplete="current-password"
                  [(ngModel)]="obj.currentPassword" #currentPassword="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && currentPassword.invalid }" required minlength="8" />
                <div *ngIf="f.submitted && currentPassword.invalid" class="invalid-feedback">
                  <div *ngIf="currentPassword.errors.required">Current Password is required</div>
                  <div *ngIf="currentPassword.errors.minlength">Current Password must be at least 8 characters</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="password" class="col-lg-4 control-label">รหัสผ่านใหม่</label>
              <div class="col-lg-8">
                <input type="password" class="form-control" name="newPassword" autocomplete="new-password"
                  [(ngModel)]="obj.newPassword" #newPassword="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && newPassword.invalid }" 
                  required 
                  minlength="8" 
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" />
                <div *ngIf="f.submitted && newPassword.invalid" class="invalid-feedback">
                  <div *ngIf="newPassword.errors.required">New Password is required</div>
                  <div *ngIf="newPassword.errors.minlength">New Password must be at least 8 characters</div>
                  <div *ngIf="newPassword.errors.pattern">
                     Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character (@$!%*?&).
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="password" class="col-lg-4 control-label">ยืนยันรหัสผ่านใหม่</label>
              <div class="col-lg-8">
                <input type="password" class="form-control" name="confirmPassword" autocomplete="confirm-password"
                  [(ngModel)]="obj.confirmPassword" #confirmPassword="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && confirmPassword.invalid }"                                      
                  required 
                  minlength="8"
                  appPasswordMatch="newPassword"                 
                  />
                <div *ngIf="f.submitted && confirmPassword.invalid" class="invalid-feedback">
                  <div *ngIf="confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="confirmPassword.errors.minlength">Confirm Password must be at least 8 characters</div>
                  <div *ngIf='confirmPassword.errors.confirmedValidator'>Passwords did not match</div>
                 
                </div>
              </div>
            </div>
            <div class="form-group ">
              <div class="col-lg-12" style="text-align:center">
                <a class="btn btn-white mr-20" [routerLink]="['/login']" >ยกเลิก</a>
                <button class="btn btn-primary" type="submit" >บันทึก</button>             
              </div>
            </div>
          </form>
        </div>
      </div>
    
    </div>
  </div>
</div>      
 