import { Component, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ng-checkbox',
  templateUrl: './ng-checkbox.template.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgCheckBoxComponent),
      multi: true
    }
  ]
})
export class NgCheckBoxComponent implements ControlValueAccessor {
  @ViewChild('checkbox') checkbox: ElementRef;

  checkboxValue: boolean = false;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(value: any): void {
    this.checkboxValue = value === 1;
    const checkboxElement = this.checkbox.nativeElement;
    console.log('checkboxValue:'+this.checkboxValue)
    checkboxElement.checked = this.checkboxValue;
  }

  registerOnChange(fn: any): void {
    this.onChange = (value: any) => {
      fn(value ? 1 : 0);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement this method if needed
  }

  toggleCheckbox(): void {
    this.checkboxValue = !this.checkboxValue;
    console.log('checkboxValue:'+this.checkboxValue)
    this.onChange(this.checkboxValue);
    this.onTouched();
  }
}
