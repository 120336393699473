import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../shared/services/dataService';
 
@Injectable()
export class AuthService {
  public authTokenStale: string = 'stale_auth_token';
  public authTokenNew: string = 'new_auth_token';
  public currentToken: string;
  constructor(private http: HttpClient,   private service: DataService) { 
    this.currentToken = this.authTokenStale;
  }
 

  logout() {
      // remove user from local storage to log user out
      localStorage.clear();
  }
 
  decryptText():any {
    /*
    var encrypt= localStorage.getItem('encrypt');
    let decodedText = atob(encrypt);
    let bytes = new Uint8Array(decodedText.length);
    for (let i = 0; i < decodedText.length; i++) {
      bytes[i] = decodedText.charCodeAt(i);
    }
    let decoder = new TextDecoder("windows-874");
    let jsonText = decoder.decode(bytes);
    let jsonObject = JSON.parse(jsonText);
    return jsonObject;
  */
  }
  getUser(): any {
    return JSON.parse(localStorage.getItem('userInfo'))
  }
  getMenu(): any {
    return JSON.parse(localStorage.getItem('menuList'))
  }
  getPermission(url): any {
    var permission= JSON.parse(localStorage.getItem('permission'))
    //console.log(permission);
    var arr=url.split('/');
    //console.log( arr.slice(0, 3).join('/'));
    var _url=arr.slice(0, 3).join('/');
    var result=permission.find(e=>e.url==_url);
    console.log(_url);
    console.log(result);
    return result;
  }
  getToken() {
    return localStorage.getItem('token');
  }

  saveUser(text): any {
    localStorage.setItem("userInfo", JSON.stringify(text));
  }
  saveToken(text): any {
    localStorage.setItem("token", text);
  }
  saveMenu(text): any {
    localStorage.setItem("menuList", JSON.stringify(text));
  }
  savePermission(text): any {
    localStorage.setItem("permission", JSON.stringify(text));
  }

   
  isLoggedIn():Boolean {
     if(localStorage.getItem('userInfo')) return true;
     else return false;
  }
 
  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  
  /*
  refreshToken(): Observable<any> {
    let body = new URLSearchParams();

    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', this.getRefreshToken());
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post<any>(this._configuration.Server +'token',  body.toString(),{headers: headers})
             
  }
  */
}
