
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>ผู้ใช้งานระบบ</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" >
              <div class="row">
                <div class="col-lg-6">
                 
                    
                 
                      <div class="form-group row">
                        <label for="firstName" class="col-lg-3 col-form-label">UserName</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="userName" [readonly]="routeParams.id != null ? true : false" [(ngModel)]="obj.userName" #userName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && userName.invalid }" required />
                          <div *ngIf="f.submitted && userName.invalid" class="invalid-feedback">
                            <div *ngIf="userName.errors.required">userName is required</div>
                          </div>
                        </div>
                      </div>
                   
                      <div class="form-group row">
                        <label for="firstName" class="col-lg-3 col-form-label">FirstName</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="firstName" [(ngModel)]="obj.firstName" #firstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required />
                          <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                            <div *ngIf="firstName.errors.required">First Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="lastName" class="col-lg-3 col-form-label">LastName</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="lastName" [(ngModel)]="obj.lastName" #lastName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required />
                          <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                            <div *ngIf="lastName.errors.required">Last Name is required</div>
                          </div>
                        </div>
                      </div>
                   
                      <div class="form-group row">
                        <label for="email" class="col-lg-3 col-form-label">Email</label>
                        <div class="col-lg-9">
                          <input type="text" class="form-control" name="email" [(ngModel)]="obj.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email />
                          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                            <div *ngIf="email.errors.required">Email is required</div>
                            <div *ngIf="email.errors.email">Email must be a valid email address</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="roleId" class="col-lg-3 col-form-label">Role</label>
                        <div class="col-lg-9">
                          <ng-select [options]="roleslist" name="roleId" [(ngModel)]="obj.roleId" #roleId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && roleId.invalid }" required></ng-select>                           
                          <div *ngIf="f.submitted && roleId.invalid" class="invalid-feedback">
                            <div *ngIf="roleId.errors.required">Role is required</div>
                          </div> 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="status" class="col-lg-3 col-form-label">Status</label>
                        <div class="col-lg-9">                        
                          <label class="switch"><input name="isActive"   #isActive="ngModel" [(ngModel)]="obj.isActive"  type="checkbox"  [checked]="obj.isActive === 1"><span class="slider round"></span></label>
                        </div>
                      </div>
                      <!--<div class="form-group row" [style.display]="routeParams.id != null ? 'none' : 'block'">
                        <label for="password" class="col-lg-3 col-form-label">รหัสผ่าน</label>
                        <div class="col-lg-9">
                          <input type="password" class="form-control" name="passwordHash" autocomplete="current-password" [readonly]="routeParams.id != null ? true : false" [(ngModel)]="obj.passwordHash" #passwordHash="ngModel" [ngClass]="{ 'is-invalid': f.submitted && passwordHash.invalid }" required minlength="6" />
                          <div *ngIf="f.submitted && passwordHash.invalid" class="invalid-feedback">
                            <div *ngIf="passwordHash.errors.required">Password is required</div>
                            <div *ngIf="passwordHash.errors.minlength">Password must be at least 6 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row" [style.display]="routeParams.id != null ? 'none' : 'block'">
                        <label for="password" class="col-lg-3 col-form-label">ตั้งรหัสผ่านใหม่</label>
                        <div class="col-lg-9">
                          <input type="checkbox" class="form-control" name="passwordSetting" [readonly]="routeParams.id != null ? true : false" [(ngModel)]="obj.passwordSetting" #passwordSetting="ngModel" [ngClass]="{ 'is-invalid': f.submitted && passwordSetting.invalid }" />
                        </div>
                      </div>--> 
                     
                    <!--<div class="row">
                      <div class="form-group row"></div>
                      <div class="form-group row" [style.display]="routeParams.id != null ? 'none' : 'block'">
                        <label for="password" class="col-lg-3 col-form-label">ตั้งรหัสผ่านใหม่</label>
                        <div class="col-lg-9" style="width:10%">
                          <input type="checkbox" class="form-control" name="passwordSetting" [readonly]="routeParams.id != null ? true : false" [(ngModel)]="obj.passwordSetting" #passwordSetting="ngModel" [ngClass]="{ 'is-invalid': f.submitted && passwordSetting.invalid }" />
                        </div>
                      </div>
                    </div>-->
                    
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>       
                   
                    <button class="btn btn-info mr-10" *ngIf="obj.lockoutEnabled" type="button" (click)="unlock()">Unlock</button>                 
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/admin/users']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>  
  </form> 
</div>
