import { Component, OnInit,ViewChild,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgTableComponent } from 'src/app/components/forms/ng-table.component';


@Component({
    selector: 'app-sub-menu-component',
    templateUrl: './sub-menu.component.html'
})

export class SubMenuComponent  implements OnInit{

  request: DataService;
  @ViewChild(NgTableComponent) table: NgTableComponent;
  
  permission: any = {};
    constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private router: Router,
      private service: DataService,
      private authService: AuthService
    ) {
     
  }
 

  async ngOnInit(): Promise<void> {
    // adding the lifecycle hook ngOnInit
    //this.service.get('users/list').subscribe(data => {
    //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
    ///});
    this.permission =   this.authService.getPermission(this.router.url);
  }
   
  public datasource: any = (params: DataService): Observable<any> => {    
    return this.service.post('menu/listsubmenu', params);
  }  
  
  
  onEdit(e) {
    this.router.navigate(['/admin/submenu/editing/'+e.id]);
  }

  onDelete(row: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("menu/deletesubmenu", row.id)
        .subscribe(response => {
          this.table.refresh()
          this.toastr.success("Delete completed.", 'Success.');
          
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }

  public columns:Array<any> = [
    { title: 'รหัส', name: 'code', sort: true},
    { title: 'ชื่อเมนูย่อย', name: 'name',sort: true,},
    { title: 'รายละเอียด', name: 'description', sort: true},
    { title: 'URL', name: 'url', sort: true },
    { title: 'ชื่อเมนูหลัก', name: 'mainMenu', sort: true },
    { title: 'ลำดับ', name: 'seq', sort: true },
    { template: 'action',width: '10%',sort: false,},
    ];
}
