import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from '../../shared/auth/auth.service';
import {DataService} from '../../shared/services/dataService';
import { HttpClient, HttpEvent, HttpHandler,HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'login',
  templateUrl: 'login.component.html',

})

export class LoginComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private toastr: ToastrService,
        private dataService:DataService,     
        private authService: AuthService) { 
           
        }

    ngOnInit() {
        // reset login status
      this.authService.logout();
      this.model={
        username:"",
        password:""
      }
        // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    }
 
  login() {

    var url = this.route.snapshot.queryParams['returnUrl'] || '/';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.http.post(environment.baseUrl + "/api/login", JSON.stringify(this.model),{headers: headers})
        .subscribe((response: any) => {
          
          this.authService.saveUser(response.userInfo);
          this.authService.saveMenu(response.menuList);
          this.authService.saveToken(response.token);
          this.authService.savePermission(response.permission);
          const userInfo =  response.userInfo;
          if (userInfo.passwordSetting == true) {          
            this.router.navigate(["/changepassword"]);
          } else {
            this.router.navigate([url]);
          }
        },                
        error => {

          //console.log(error)
          if (error.error.message != null && error.error.message != "")
            this.toastr.error(error.error.message, "Message");
            //console.log(error.error.message)
          else
            this.toastr.error(error.message, "Message");
            //console.log(error.error.message)
          });
    }
}
