import { Component, OnInit,ViewChild,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
 
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgTableColumn, NgTableFormat } from 'src/app/components/forms/ng-table.inferface';
import { NgTableComponent } from 'src/app/components/forms/ng-table.component';
 

@Component({
    selector: 'app-bank-component',
    templateUrl: './bank.component.html'
})

export class BankComponent  implements OnInit{

  request: DataService;
  permission: any = {};
  data:any;
  @ViewChild(NgTableComponent) table: NgTableComponent;
  
  constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private router: Router,
      private service: DataService,
      private authService: AuthService
    ) {
        
  }
 
  ngOnInit() {
 
     this.permission =   this.authService.getPermission(this.router.url);
     
  }
 
  
  public datasource = (params: any): Observable<any> => {    
    return this.service.post(`bank/list`,params);
  };

 
 
  onDelete(obj: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("bank/delete", obj.id)
        .subscribe(response => {
          this.table.refresh()
          this.toastr.success("Delete completed.", 'Success.');          
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }
  onEdit(e) {
    this.router.navigate(['/setup/bank/editing/'+e.id]);
  }
 

  public columns: Array<NgTableColumn> = [  
    
    { title: 'Code', name: 'code', sort: true },
    { title: 'Name', name: 'name', sort: true },    
    { title: 'CreateDate', name: 'createDate',width: '20%',format: NgTableFormat.DateTime,},
    { title: 'Crerated',align:'center', name: 'isActive',width: '10%',sort: false,},
    { template: 'action',width: '10%',sort: false,},
  ];
}
