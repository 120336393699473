import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'users-form',
    templateUrl: './users-form.component.html'
})

export class UsersFormComponent  implements OnInit{
 
    submitted = false;
    routeParams = null;
    obj: any = {isActive:1};
    roleslist = [];
    permission: any = {};
    id = 0;
    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private service: DataService,
        private authService: AuthService
    ) {
       
    }
 
  async ngOnInit(): Promise<void> {
      // adding the lifecycle hook ngOnInit
      //this.service.get('users/list').subscribe(data => {
      //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
      ///});
      
      this.routeParams = this.activeRoute.snapshot.params;
       
      //console.log(this.router);
      if(this.routeParams.id != null) this.getRow(this.routeParams.id) ;
      //this.permission = await this.authService.getPermission("users");
      this.permission =   this.authService.getPermission(this.router.url);
      //console.log(this.router.url);
      this.getRoleList();
      //this.toastr.success("Delete completed.", 'Success.');
    }
 
    getRow(id: any) {
      this.service.get("users/get/"+id)
        .subscribe(response => {
          this.obj = response;
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
    unlock() {
      this.service.post("users/unlock/"+this.obj.id, null)
      .subscribe((response: any) => {
        this.toastr.success(response.message);
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
    getRoleList() {
      this.service.post("roles/getroleslist", null)
        .subscribe((response: any) => {
          this.roleslist = response;
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
    onSubmit() {
      this.obj.isActive = (this.obj.isActive)?1:0;
      this.service.post("users/post", this.obj)
        .subscribe((response: any) => {
          //this.obj = response;
          this.toastr.success("Create or Update Completed.", 'Succcess');
          this.router.navigate(['/admin/users']);
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
        });
    
    }
}
