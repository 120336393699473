import { Component, Input, Output, EventEmitter, OnInit, TemplateRef, ViewChild, QueryList, ViewChildren, ContentChildren, AfterContentInit, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { NgTableColumn, NgTableFormat } from './ng-table.inferface';

@Component({
  selector: 'ng-table',
  templateUrl: './ng-table.component.html',
  styleUrls: ['./ng-table.component.css']
})
export class NgTableComponent implements OnInit,AfterViewInit, AfterContentInit     {
  @Input() columns: Array<NgTableColumn> = [];
  @Input() defaultSortColumn: string;
  @Input() defaultSortDirection: string;
  @Input() allowFilter: boolean = true;
  @Input() allowCheckbox: boolean = false;
  @Input() allowPaging: boolean = true;
  //@Input() css: String = "table table-striped";
  @Output() selectRow = new EventEmitter<any>();
  @Output() checkedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() datasource: (params: any) => Observable<any>;
  @ContentChildren(TemplateRef, { read: TemplateRef, descendants: false }) templates: QueryList<TemplateRef<any>>;

  data: any[]=[];
  currentPage = 1;
  pageSize = 10;
  totalItems: number;
  sortColumn: string;
  dateFormat: string='yyyy-MM-dd';
  dateTimeFormat: string='yyyy-MM-dd HH:mm';
  sortDirection = 'asc';
  filterText: string = '';
  sortIcons = ['fa-sort', 'fa-sort-up', 'fa-sort-down'];
  templateMap: { [key: string]: TemplateRef<any> } = {};

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.sortColumn = this.defaultSortColumn || this.columns[0].name;
    this.sortDirection = this.defaultSortDirection || 'asc';
    this.loadData();
    
  }
  checkedItems = new Set(); // Keep track of checked items


  private emitCheckedItems(): void {
    this.checkedItemsChange.emit(Array.from(this.checkedItems));
  }
  filter() {
    this.currentPage=1;
    this.loadData();
  }
  refresh() {
    // Here, you can add any code that updates the data source
    // and causes the table to refresh. For example:
    this.data=[];
    this.loadData();
  }

  isAllChecked(): boolean {
    return this.data.length > 0 && this.checkedItems.size === this.data.length;
  }

  onCheckAllChange(checked: boolean): void {
    if (checked) {
      this.data.forEach((item) => this.checkedItems.add(item));
    } else {
      this.checkedItems.clear();
    }
    this.emitCheckedItems();
  }

  onItemCheckChange(item: any, checked: boolean): void {
    if (checked) {
      this.checkedItems.add(item);
    } else {
      this.checkedItems.delete(item);
    }
    this.emitCheckedItems();
  }

  formatValue(value: any, format: NgTableFormat) {
    if (format === NgTableFormat.DateTime) {
      return formatDate(value, this.dateTimeFormat, 'en-US');
    } else if (format === NgTableFormat.Date) {
      return formatDate(value, this.dateFormat, 'en-US');       
    } else if (format === NgTableFormat.Number) {
      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    } else if (format === NgTableFormat.Masking) {
      return   value.replace(/\d(?=\d{4})/g, "*");
    }   else {
      return value;
    }
  }
  
  ngAfterContentInit() {
    this.templates.forEach((template: TemplateRef<any>) => {
      const name = template['_declarationTContainer'].localNames[0];
      this.templateMap[name] = template;
    });
    //console.log(this.templateMap)
  }

  ngAfterViewInit() {
  }
  
  loadData() {
    const params = {
      page: this.currentPage,
      limit: this.pageSize,
      filter: this.filterText,
      order: `${this.sortColumn} ${this.sortDirection}`
    };
 
    this.datasource(params).subscribe((response: any) => {
      this.data = response.data;  
      if(response.columns) {
        const columns=response.columns;
        this.columns= columns.map((columnName: string) => {
          let column: NgTableColumn = { name: columnName, title: columnName,sort:false };
          return column;
        });
        this.totalItems=this.data.length;
      }   
      //console.log(this.columns)
      else this.totalItems = response.total;
    });
  
  }

  onSelectRow(row: any) {
    this.selectRow.emit(row);
  }

  onSort(column: any) {
    if (column.name === this.sortColumn) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column.name;
      this.sortDirection = 'asc';
    }
    this.loadData();
  }

  onPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadData();
    }
  }

  onNextPage() {
    if (this.currentPage < this.totalPages()) {
      this.currentPage++;
      this.loadData();
    }
  }

  onFirstPage() {
    if (this.currentPage > 1) {
      this.currentPage=1;
      this.loadData();
    }
  }

  onLastPage() {
    if (this.currentPage < this.totalPages()) {
      this.currentPage=this.totalPages();
      this.loadData();
    }
  }

  onPage(page: number) {
    if (page >= 1 && page <= this.totalPages()) {
      this.currentPage = page;
      this.loadData();
    }
  }
  
  getPages(): number[] {
    const totalPages = this.totalPages();
    const currentPage = this.currentPage;
    let startPage: number, endPage: number;
  
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
  
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
    return pages;
  }
  totalPages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  sortIcon(column: any) {
    if (column.name === this.sortColumn) {
      return this.sortDirection === 'asc' ? this.sortIcons[1] : this.sortIcons[2];
    } else {
      return this.sortIcons[0];
    }
  }

  pageIcon(icon: string, isDisabled: boolean) {
    const className = `fa ${icon} ${isDisabled ? 'disabled' : ''}`;
    return className;
  }
  
  cellTemplate(name: string, row: any) {
    const column = this.columns.find((col: any) => col.name === name);
    const entries = row[name]
    //console.log(entries)
    if (row[name]==undefined) { 
      return  this.templateMap[name];
      //return template({ item: row }) || null;
    }
    else {       
      return row[column.name];
    }
  }
}
