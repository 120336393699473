import { Component, OnInit,ViewChild,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
 
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgTableColumn, NgTableFormat } from 'src/app/components/forms/ng-table.inferface';
import { NgTableComponent } from 'src/app/components/forms/ng-table.component';
import { AlertService } from 'src/app/shared/services/AlertService';
 
@Component({
    selector: 'app-users-component',
    templateUrl: './users.component.html'
})

export class UsersComponent  implements OnInit{

  request: DataService;
  permission: any = {};
  data:any;
  @ViewChild(NgTableComponent) table: NgTableComponent;
  
  constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private router: Router,
      private service: DataService,
      private authService: AuthService,
      private alertService: AlertService
    ) {
        
  }
 
  ngOnInit() {
    // adding the lifecycle hook ngOnInit
    //this.service.get('users/list').subscribe(data => {
    //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
    ///});
    //console.log(this.router.snapshot.data['title']);
    //this.permission =  getPermission(this.router.url);
   
    this.permission =   this.authService.getPermission(this.router.url);
    //Swal.fire('Thank you...', 'You submitted succesfully!', 'success')

  }
 
  
  public datasource = (params: any): Observable<any> => {    
    return this.service.post(`users/list`,params);
  };

 
 
  onDelete(obj: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("users/delete", obj.id)
        .subscribe(response => {
          this.table.refresh()
          this.toastr.success("Delete completed.", 'Success.');          
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }
  onEdit(e) {
    this.router.navigate(['/admin/users/editing/'+e.id]);
  }

  checkedItemIds: any[] = []; // Array to store checked item ids

  onCheckedItemsChange(checkedItems: any[]): void {
    this.checkedItemIds = checkedItems.map((item) => item.id);
    console.log('Checked item ids:', this.checkedItemIds);
  }

  public columns: Array<NgTableColumn> = [  
    { title: 'Name', name: 'fullName', sort: true },
    { title: 'UserName', name: 'userName', sort: true },
    { title: 'Role', name: 'roleName', sort: true },    
    { title: 'Email', name: 'email', sort: true },
    { title: 'CreateDate', name: 'createDate',width: '20%',format: NgTableFormat.DateTime,},
    { title: 'Locked',align:'center', name: 'LockoutEnabled',template: 'locked',width: '10%',sort: false,},
    { title: 'Status',align:'center', name: 'isActive',template: 'status',width: '10%',sort: false,},
    { template: 'action',width: '10%',sort: false,},
  ];
}
