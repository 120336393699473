import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/shared/services/AlertService';
import { NgForm } from '@angular/forms';
 
@Component({
    selector: 'dipchip-form',
    templateUrl: './dipchip-form.component.html'
})

export class DipChipComponent  implements OnInit{
    @ViewChild('f') form: NgForm;
    submitted = false;
    routeParams = null;
  /*   obj: any = {
      FirstNameTH:"พีระพันธ์",
      LastNameTH:"สังข์พันธุ์",
      IDCardNo:"3909900340139",
      BirthDateTypeId:1,
      LaserId:"ME1122533391",
      signature:"Peerapun Sangpun"
    }; */
    obj: any = {
      FirstNameTH:"",
      LastNameTH:"",
      IDCardNo:"",
      BirthDateTypeId:1,
      LaserId:"",
      signature:""
    }; 
    showmonth:boolean=true;
    showday:boolean=true;
    verify:any ={};
    success: boolean=false;
    message:string;
    roleslist = [];
    birthDateTypeList :any=[
      {"id":1,"name":"มีวัน-เดือน-ปีเกิด"},
      {"id":2,"name":"มีเฉพาะเดือน-ปีเกิด"},
      {"id":3,"name":"มีเฉพาะปีเกิด "},
    ];
    permission: any = {};
    id = 0;

    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private service: DataService,
        private alertService: AlertService,
        private authService: AuthService
    ) {
       
    }
 
  async ngOnInit(): Promise<void> {
      // adding the lifecycle hook ngOnInit
      //this.service.get('users/list').subscribe(data => {
      //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
      ///});
      
      this.routeParams = this.activeRoute.snapshot.params;
      //this.getBirthDateType();
      /*
      this.obj.IDCardNo="3909900340139";
      this.obj.FirstNameTH="พีระพันธ์";
      this.obj.LastNameTH="สังข์พันธุ์";
      this.obj.LaserId="ME1122533391";
      */
      //this.obj.BirthDateTypeId=1;
      this.routeParams = this.activeRoute.snapshot.params;
      if (this.routeParams.refcode != null)  this.getRow(this.routeParams.refcode) ;
      //this.obj.BirthDate='20'
    }
 
    getRow(refcode) {
      this.service.get("personal/validate/"+refcode)
        .subscribe(response => {
           //console.log(response)
           this.verify=response
        },
        error => {
          //console.log(error)
          this.message=error.error.message
          //this.toastr.error(error.error.message, 'Error!');
        });
    }
    onChange(e) {
      console.log(e)
       if(e==1) {
         this.showmonth=true;
         this.showday=true;
       }else if(e==2) {
        this.showmonth=true;
        this.showday=false;
       }else if(e==3) {
        this.showmonth=false;
        this.showday=false;
       }
    }
    onSubmit() {
     console.log(this.obj)
      
      this.service.post("personal/verifyidcard",this.obj)
        .subscribe((response: any) => {
          //this.toastr.success("Create or Update Completed.", 'Succcess');
         // this.success=true;
         this.form.resetForm();
         this.obj={BirthDateTypeId:1}
         this.alertService.success('ดำเนินการตรวจ DOPA & AMLO เรียบร้อยแล้ว')
        },
        error => {
          this.alertService.error(error.error.message)
          //this.toastr.error(error.error.message, 'Error!');
          //this.message=error.error.message;
        });
       
    }
}
