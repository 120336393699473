import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BasicLayoutComponent } from './components/layouts/basicLayout.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { BlankLayoutComponent } from './components/layouts/blankLayout.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { UsersComponent } from './views/users/users.component';
import { UsersFormComponent } from './views/users/users-form.component';
import { AccountFormComponent } from './views/account/account-form.component';
import { AccountComponent } from './views/account/account.component';
import { SettingFormComponent } from './views/setting/setting-form.component';
import { RoleComponent } from './views/roles/roles.component';
import { RoleFormComponent } from './views/roles/roles-form.component';
import { NotfoundComponent } from './views/notfound/notfound.component';
import { MainMenuComponent } from './views/menu/main-menu.component';
import { MainMenuFormComponent } from './views/menu/main-menu-form.component';
import { SubMenuFormComponent } from './views/menu/sub-menu-form.component';
import { SubMenuComponent } from './views/menu/sub-menu.component';
import { GeneralComponent } from './views/general/general.component';
import { GeneralFormComponent } from './views/general/general-form.component';
import { TitleComponent } from './views/title/title.component';
import { TitleFormComponent } from './views/title/title-form.component';
import { CountryFormComponent } from './views/country/country-form.component';
import { CountryComponent } from './views/country/country.component';
import { ProvinceComponent } from './views/province/province.component';
import { ProvinceFormComponent } from './views/province/province-form.component';
import { PersonalComponent } from './views/personal/personal.component';
import { SqlAnalyzerComponent } from './views/tools/sql-analyzer.component';
import { DipChipComponent } from './views/personal/dipchop-form.component';
import { UsersChangePasswordComponent } from './views/users/users-changepassword.component';
import { BankFormComponent } from './views/bank/bank-form.component';
import { BankComponent } from './views/bank/bank.component';
import { InvestorComponent } from './views/investor/investor.component';
import { InvestorFormComponent } from './views/investor/investor-form.component';
 ;
const routes: Routes = [
  { path: '', redirectTo: '/dashobard', pathMatch: 'full' , canActivate: [AuthGuard] },

  {
    path: 'admin', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      
          { path: 'users', component: UsersComponent  },
          { path: 'users/create', component: UsersFormComponent  }, 
          { path: 'users/editing/:id', component: UsersFormComponent  },
      
          { path: 'setting', component: SettingFormComponent },

          { path: 'roles', component: RoleComponent },
          { path: 'roles/create', component: RoleFormComponent },
          { path: 'roles/editing/:id', component: RoleFormComponent },

          { path: 'mainmenu', component: MainMenuComponent },
          { path: 'mainmenu/create', component: MainMenuFormComponent  },
          { path: 'mainmenu/editing/:id', component: MainMenuFormComponent  },

          { path: 'submenu', component: SubMenuComponent  },
          { path: 'submenu/create', component: SubMenuFormComponent },
          { path: 'submenu/editing/:id', component: SubMenuFormComponent  },

          { path: 'sqlanalyzer', component: SqlAnalyzerComponent  },
          
    ]
  },
  {
    path: 'setup', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
         
      { path: 'general', component: GeneralComponent  },
      { path: 'general/create', component: GeneralFormComponent },
      { path: 'general/editing/:id', component: GeneralFormComponent  },

      { path: 'title', component: TitleComponent  },
      { path: 'title/create', component: TitleFormComponent },
      { path: 'title/editing/:id', component: TitleFormComponent  },

      { path: 'bank', component: BankComponent  },
      { path: 'bank/create', component: BankFormComponent },
      { path: 'bank/editing/:id', component: BankFormComponent  },

      { path: 'country', component: CountryComponent  },
      { path: 'country/create', component: CountryFormComponent },
      { path: 'country/editing/:id', component: CountryFormComponent  },

      { path: 'investor', component: InvestorComponent  },
      { path: 'investor/create', component: InvestorFormComponent },
      { path: 'investor/editing/:id', component: InvestorFormComponent  },

      { path: 'province', component: ProvinceComponent  },
      { path: 'province/create', component: ProvinceFormComponent },
      { path: 'province/editing/:id', component: ProvinceFormComponent  },
    ]
  }, 
  {
    path: 'account', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
         
      { path: 'eoacc', component: AccountComponent },
      { path: 'eoacc/editing/:id', component: AccountFormComponent},
      { path: 'eoacc/create', component: AccountFormComponent},
      { path: 'personal', component: PersonalComponent  },
  
      //{ path: 'personal', component: PeronsalComponent },
    ]
  },  
  {
    path: '', component: BlankLayoutComponent, canActivate: [AuthGuard],
    children: [         
      { path: 'changepassword', component: UsersChangePasswordComponent }, 
    ]
  },  

  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'dipchip/:refcode', component: DipChipComponent },
    ]
  },

 

  {
    path: '', component: BasicLayoutComponent , canActivate: [AuthGuard],
    children: [
      { path: 'dashobard', component: DashboardComponent },
      { path: '**', component: NotfoundComponent }
    ]
  },  
];
 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
