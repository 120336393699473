<div >
  <div style="width:33%;display:inline-block;" >
    <select id="year" class="form-control" [(ngModel)]="selectedYear" (change)="validate()" >
      <option [ngValue]="null"  selected>ปี</option>
      <option *ngFor="let year of years" [value]="year-543" > {{ (year)  }}</option>
    </select>
  </div>

  <div style="width:43%;display:inline-block;margin-left: 5px;"  *ngIf="showMonth">
    <select id="month" class="form-control" [(ngModel)]="selectedMonth" (change)="validate()">
      <option [ngValue]="null" selected>เดือน</option>
     
      <option *ngFor="let month of months;let i = index" [value]="i+1">{{month}}</option>
    </select>
  </div>

  <div style="width:20%;display:inline-block;margin-left: 5px;" *ngIf="showDay">
    <select id="day" class="form-control" [(ngModel)]="selectedDay" (change)="validate()">
      <option [ngValue]="null" selected>วัน</option>
      
      <option *ngFor="let day of days" [value]="day">{{ day   }}</option>
    </select>
  </div>
</div>
