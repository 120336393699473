import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';;
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'sub-menu-form',
    templateUrl: './sub-menu-form.component.html'
})

export class SubMenuFormComponent implements OnInit{
  loading = false;
  submitted = false;
  routeParams = null;
  obj: any = {status:1};
  mainmenulist = [];
  roleslist = [];
  permission: any = {};
  id = 0;
  view = 0;
  add = 0;
  edit = 0;
  del = 0;
  approve = 0;
    constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private router: Router,
      private activeRoute: ActivatedRoute,
      private service: DataService,
      private authService: AuthService
    ) {
        
    }
 
  async ngOnInit(): Promise<void> {
    // adding the lifecycle hook ngOnInit
    //this.service.get('users/list').subscribe(data => {
    //  console.log(data); // using the HttpClient instance, http to call the API then subscribe to the data and display to console
    ///});
      
    this.routeParams = this.activeRoute.snapshot.params;
    if (this.routeParams.id != null) this.getRow(this.routeParams.id);
    this.permission =   this.authService.getPermission(this.router.url);
    this.getRoleList();
    this.getSelectMainMenu();
    }
    getRow(id: any) {
      this.service.get("menu/getsubmenu/"+id)
        .subscribe(response => {
          if (response != null) {
            this.obj = response;
            this.view = this.obj.view;
            this.add = this.obj.add;
            this.edit = this.obj.edit;
            this.del = this.obj.del;
            this.approve = this.obj.approve;
          }
          
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }

  getByCreate() {
    this.service.get("menu/createsubmenu")
      .subscribe((response: any) => {
        this.obj = response;
      },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
  }
  chkPermissionView(event) {
    this.view = (event.srcElement.checked ? 1 : 0);
  }
  chkPermissionAdd(event) {
    this.add = (event.srcElement.checked ? 1 : 0);
  }
  chkPermissionEdit(event) {
    this.edit = (event.srcElement.checked ? 1 : 0);
  }
  chkPermissionDel(event) {
    this.del = (event.srcElement.checked ? 1 : 0);
  }
  chkPermissionApprove(event) {
    this.approve = (event.srcElement.checked ? 1 : 0);
  }
  getSelectMainMenu() {
    this.service.post("menu/getselectmainmenu", null)
      .subscribe((response: any) => {
        this.mainmenulist = response;
        if (this.routeParams.id == null) this.obj.menuId = ""; 
      },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
  }

  getRoleList() {
    this.service.post("roles/getroleslist", null)
      .subscribe((response: any) => {
        this.roleslist = response;
      },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
  }
    onSubmit() {
      //this.submitted = true;
      this.obj.view = this.view;
      this.obj.add = this.add;
      this.obj.edit = this.edit;
      this.obj.del = this.del;
      this.obj.approve = this.approve;
      this.obj.seq = parseInt(this.obj.seq);
      //check validate form
      this.loading = true;
      this.service.post("menu/savesubmenu", this.obj)
        .subscribe((response: any) => {
          this.loading = false;
          //this.obj = response;
          this.toastr.success("Create or Update Completed.", 'Succcess');
          this.router.navigate(['/admin/submenu']);
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    
    }
}
