import { Component, Input, ViewChild, ElementRef, forwardRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare var $: any;

const CHOSEN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgSelectComponent),
  multi: true
};

@Component({
  selector: 'ng-select',
  templateUrl: 'ng-select.template.html',
  providers: [CHOSEN_VALUE_ACCESSOR]
})
export class NgSelectComponent implements ControlValueAccessor {

  @Input() options:any=[];
  @Input() multiple: boolean = false;
  @Input() optionLabel: string ='name';
  @Input() optionValue: string ='id';
  @Input() allOptionText: string = '--- Please Select ---';

  @Input() allowEmpty: boolean = true;
   
  
  @ViewChild('selectBox', { static: true }) selectBox: ElementRef;

  private onChange: any = () => {};
  private onTouched: any = () => {};
  private selectedValue: any;

  writeValue(value: any): void {
    const select = $(this.selectBox.nativeElement);
    //console.log(value)
    select.val(value);
    
    setTimeout(() => {
      $(select).val(value).trigger("chosen:updated")
    },500);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    $(this.selectBox.nativeElement).chosen();

    $(this.selectBox.nativeElement).on('change', (e: any) => {
      this.onChange($(e.target).val());
    });
  }
 


   
  ngOnChanges() {
    if (this.options) {
      const select = $(this.selectBox.nativeElement);
      let options = this.options;
  
      if (this.allowEmpty && this.multiple==false) {
        const allOption = {};
        allOption[this.optionValue] = '';
        allOption[this.optionLabel] = this.allOptionText;
        this.options = [allOption, ...options];
      }
  
      const optionsHtml = this.options.map(option => `<option value="${option[this.optionValue]}">${option[this.optionLabel]}</option>`).join('');
      select.html(optionsHtml);
  
      setTimeout(() => {
        $(select).trigger('chosen:updated');
     
      }, 100);
    }
  
    if (this.multiple) {
      $(this.selectBox.nativeElement).attr('multiple', 'true');
    } else {
      $(this.selectBox.nativeElement).removeAttr('multiple');
    }
  }

 
}
