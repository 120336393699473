import { Component, OnInit,ViewChild,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgTableColumn } from 'src/app/components/forms/ng-table.inferface';
import { NgTableComponent } from 'src/app/components/forms/ng-table.component';


@Component({
  selector: 'app-roles-component',
    templateUrl: './roles.component.html'
})

export class RoleComponent  implements OnInit{

  request: DataService;
  @ViewChild(NgTableComponent) table: NgTableComponent;
  
  permission: any = {};
    constructor(
      private toastr: ToastrService,
      private http: HttpClient,
      private router: Router,
      private service: DataService,
      private authService: AuthService
    ) {
        
       }
 
 
  async ngOnInit(): Promise<void> {
 
    this.permission =   this.authService.getPermission(this.router.url);
  }
 
  public datasource = (params: any): Observable<any> => {    
    return this.service.post(`roles/list`,params);
  };
 
  onEdit(e) {
    this.router.navigate(['/admin/roles/editing/'+e.id]);
  }
 
  onDelete(row: any) {
    if (confirm("Are you sure delete this record?")) {
      this.service.delete("roles/delete", row.id)
        .subscribe(response => {
          this.toastr.success("Delete completed.", 'Success.');
          this.table.refresh()
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }

  public columns: Array<NgTableColumn> = [  
    {title: 'รหัส', name: 'code', sort: true},
    {title: 'ชื่อสิทธิ์', name: 'name', sort: true},
    {title: 'รายละเอียด', name: 'description', sort: true},    
    {title: 'Action', template: 'action',width: '10%',sort: false,},
  ];
 
}
