import { Component, EventEmitter, Input, OnInit,Output,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'bank-account-form',
    templateUrl: './bankaccount-form.component.html'
})

export class BankAccountFormComponent  implements OnInit{
    @Input() data: any={};
    @Output() formSubmitted: EventEmitter<void> = new EventEmitter<void>();
    submitted = false;
    routeParams = null;
    obj: any = {};
    bankList: any  = [];
    bankAccountTypeList :any =[]
    permission: any = {};
    id = 0;
    constructor(
        private toastr: ToastrService,
        private service: DataService,
        public activeModal: NgbActiveModal,
        private authService: AuthService
    ) {
       
    }
 
    ngOnInit() {
      //this.routeParams = this.activeRoute.snapshot.params;
      //console.log(this.data.id)
      this.obj=this.data;
      console.log(this.obj)
      this.getBank()
      this.getBankAccountType()
    }
    getBank() {
        this.service.get("bank/getall").subscribe((response: any) => {
          this.bankList = response;
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
    getBankAccountType() {
      this.service.get("general/getall/bankaccounttype").subscribe(response => {
        this.bankAccountTypeList = response;
        //console.log(this.bankList)
      },error => {        
        this.toastr.error(error.message, 'Error!');
      });
  }
    getRow(id: any) {
      
    }
    hide(){
      this.activeModal.close();
    }
    onSubmit() { 
      //this.obj.accountId=this.id
      this.obj.isATS=(this.obj.isATS)?1:0;
      this.obj.isDefault=(this.obj.isDefault)?1:0;
      this.obj.active=(this.obj.active)?1:0;
      this.service.post("bankaccount/post", this.obj)
        .subscribe((response: any) => {
          //this.obj = response;
          this.activeModal.close(this.obj);
          //this.formSubmitted.emit()
          this.toastr.success("Create or Update Completed.", 'Succcess');         
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
        });
    
    }
}
