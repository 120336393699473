
<div class="wrapper wrapper-content container animated fadeInRight">
  <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <h5>สิทธิ์ผู้ใช้งาน</h5>
              <!--<iboxtools></iboxtools>-->
            </div>
            <div class="ibox-content" >
              <div class="row">
                <div class="col-lg-12">
                 
                    <div class="form-group row">
                      <label for="code" class="col-lg-3 col-form-label">Code</label>
                      <div class="col-lg-6">
                        <input type="text" class="form-control" name="code" [(ngModel)]="obj.code" #code="ngModel" [ngClass]="{ 'is-invalid': f.submitted && code.invalid }" required />
                        <div *ngIf="f.submitted && code.invalid" class="invalid-feedback">
                          <div *ngIf="code.errors.required">code is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="name" class="col-lg-3 col-form-label">Name</label>
                      <div class="col-lg-6">
                        <input type="text" class="form-control" name="name" [(ngModel)]="obj.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                          <div *ngIf="name.errors.required">Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="description" class="col-lg-3 col-form-label">Description</label>
                      <div class="col-lg-6">
                        <input type="text" class="form-control" name="description" [(ngModel)]="obj.description" #description="ngModel" />
                      
                      </div>
                    </div>
                    <div  *ngIf="obj.id">
                      <table class="table table-hover no-margins">
                        <thead>
                          <tr>
                            <th style="width:40%;border-bottom: 1px #e7eaec solid;">Main Menu</th>
                            <th style="width:12%;border-bottom: 1px #e7eaec solid;">View</th>
                            <th style="width:12%;border-bottom: 1px #e7eaec solid;">Add</th>
                            <th style="width:12%;border-bottom: 1px #e7eaec solid;">Edit</th>
                            <th style="width:12%;border-bottom: 1px #e7eaec solid;">Delete</th>
                            <th style="width:12%;border-bottom: 1px #e7eaec solid;">Aprove</th>
                          </tr>
                        </thead>
                      </table>
                      <div *ngFor="let per of obj.permission let i = index">
                        <!--<p style="padding: 8px;margin:0px">{{per.menuName}}</p>-->
                        <table class="table table-hover no-margins">
                          <tbody>
                            <tr>
                              <td style="width:40%;border-bottom: 1px #e7eaec solid;"><span style="padding-left: 20px;">{{per.menuName}}</span></td>
                              <td style="border-bottom: 1px #e7eaec solid;">
                                <label class="ui-checkbox ui-checkbox-primary">
                                  <input type="checkbox" [(ngModel)]="per.isShow" [ngModelOptions]="{standalone: true}" [checked]="per.isShow" /> <span class="input-span"></span>
                                </label>
                                
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-hover no-margins">
                          <tbody>
                            <tr *ngFor="let sub of per.permissionList let n = index">
                              <td style="width:40%;border-bottom: 1px #e7eaec solid;"><span style="padding-left: 40px;">{{sub.subMenuName}}</span></td>
                              <td style="width:12%;border-bottom: 1px #e7eaec solid;">
                                <label class="ui-checkbox ui-checkbox-primary">
                                  <input type="checkbox" [style.display]="obj.permission[i].permissionList[n].canRead ? 'block' : 'none'" [(ngModel)]="obj.permission[i].permissionList[n].isRead" [ngModelOptions]="{standalone: true}" [checked]="obj.permission[i].permissionList[n].isRead" />
                                  <span class="input-span"></span>
                               </label>
                              </td>
                              <td style="width:12%;border-bottom: 1px #e7eaec solid;">
                                <label class="ui-checkbox ui-checkbox-primary">
                                  <input type="checkbox" [style.display]="obj.permission[i].permissionList[n].canAdd ? 'block' : 'none'" [(ngModel)]="obj.permission[i].permissionList[n].isAdd" [ngModelOptions]="{standalone: true}" [checked]="obj.permission[i].permissionList[n].isAdd" />
                                  <span class="input-span"></span>
                                </label>
                              </td>
                              <td style="width:12%;border-bottom: 1px #e7eaec solid;">
                                <label class="ui-checkbox ui-checkbox-primary">
                                <input type="checkbox" [style.display]="obj.permission[i].permissionList[n].canEdit ? 'block' : 'none'" [(ngModel)]="obj.permission[i].permissionList[n].isEdit" [ngModelOptions]="{standalone: true}" [checked]="obj.permission[i].permissionList[n].isEdit" />
                                <span class="input-span"></span>
                              </label>
                              </td>
                              <td style="width:12%;border-bottom: 1px #e7eaec solid;">
                                <label class="ui-checkbox ui-checkbox-primary">
                                <input type="checkbox" [style.display]="obj.permission[i].permissionList[n].canDelete ? 'block' : 'none'" [(ngModel)]="obj.permission[i].permissionList[n].isDelete" [ngModelOptions]="{standalone: true}" [checked]="obj.permission[i].permissionList[n].isDelete" />
                                <span class="input-span"></span>
                              </label>
                              </td>
                              <td style="width:12%;border-bottom: 1px #e7eaec solid;">
                                <label class="ui-checkbox ui-checkbox-primary">
                                  <input type="checkbox" [style.display]="obj.permission[i].permissionList[n].canApprove ? 'block' : 'none'" [(ngModel)]="obj.permission[i].permissionList[n].isApprove" [ngModelOptions]="{standalone: true}" [checked]="obj.permission[i].permissionList[n].isApprove" />
                                  <span class="input-span"></span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                 
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-lg-12 text-right">
                    <div class="clearfix"></div>                     
                    <button class="btn btn-primary" type="submit">Submit</button>    
                    <a class="btn btn-default" [routerLink]="['/admin/roles']">Cancel</a>                
                  </div>
                </div>
            </div>
          </div>
     </div>   
    </div>  
  </form>  
</div>
