
  <div class="wrapper wrapper-content container animated fadeInRight  " style="max-width: 600px;"> 
    <div class="row">
    
      <div class="col-lg-12">
        
        
        <form name="form" class="form-horizontal" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate  autocomplete="off"  >
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h3>ตรวจสอบสถานะ DOPA & AMLO </h3>
          </div>
          <div class="ibox-content"> 
            <h3 class="required">**Links will expire on  {{verify.expireDate|date :'short'}}</h3>
            <div class="col-md-12"  >
             
              
              <div class="form-group row">
                <label for="firstNameTH" class="col-lg-4 col-form-label">ชื่อ</label>
                <div class="col-lg-8">
                  <input type="text" class="form-control" name="firstNameTH" [(ngModel)]="obj.FirstNameTH" #firstNameTH="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstNameTH.invalid }" required />
                  <div *ngIf="f.submitted && laserId.invalid" class="invalid-feedback">
                    <div *ngIf="firstNameTH.errors.required">Company Name is required</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="lastNameTH" class="col-lg-4 col-form-label">นามสกุล</label>
                <div class="col-lg-8">
                  <input type="text" class="form-control" name="lastNameTH" [(ngModel)]="obj.LastNameTH" #lastNameTH="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastNameTH.invalid }" required />
                  <div *ngIf="f.submitted && laserId.invalid" class="invalid-feedback">
                    <div *ngIf="lastNameTH.errors.required">กรุณาระบุนามสกุล</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="IdCardNo" class="col-lg-4 col-form-label">หมายเลขบัตรประชาชน</label>
                <div class="col-lg-8">
                  <input type="text" class="form-control" name="IdCardNo" [(ngModel)]="obj.IDCardNo" #IdCardNo="ngModel" [ngClass]="{ 'is-invalid': f.submitted && IdCardNo.invalid }" required />
                  <div *ngIf="f.submitted && IdCardNo.invalid" class="invalid-feedback">
                    <div *ngIf="IdCardNo.errors.required">กรุณาระบุหมายเลขบัตรประชาชน</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="birthDateTypeId" class="col-lg-4 col-form-label"> รูปแบบวันเกิดในบัตร</label>
                <div class="col-lg-8">
                  <ng-select [options]="birthDateTypeList" name="birthDateTypeId" [(ngModel)]="obj.BirthDateTypeId"  (ngModelChange)="onChange($event)"  #birthDateTypeId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && birthDateTypeId.invalid }" required></ng-select>                           
                  <div *ngIf="f.submitted && birthDateTypeId.invalid" class="invalid-feedback">
                    <div *ngIf="birthDateTypeId.errors.required">กรุณาระบุรูปแบบวันเกิดในบัตร</div>
                  </div> 
                </div>
              </div>

              <div class="form-group row">
                <label for="birthDate" class="col-lg-4 col-form-label"> วันเกิด</label>
                <div class="col-lg-8">
                  <ng-birthdate  name="birthDate" [showMonth]="showmonth" [showDay]="showday"  [(ngModel)]="obj.BirthDate" #birthDate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && birthDate.invalid }" required></ng-birthdate>                           
                  <div *ngIf="f.submitted && birthDate.invalid" class="invalid-feedback">
                    <div *ngIf="birthDate.errors.required">กรุณาระบุวันเกิด</div>
                    <div *ngIf="birthDate.errors.invalidDate">รูปแบบวันเกิดไม่ถูกต้อง</div>
                  </div> 
                </div>
              </div>
              
              <div class="form-group row">
                <label for="laserId" class="col-lg-4 col-form-label">เลขหลังบัตรประชาชน</label>
                <div class="col-lg-8">
                  <input type="text" class="form-control" name="laserId" [(ngModel)]="obj.LaserId" #laserId="ngModel" [ngClass]="{ 'is-invalid': f.submitted && laserId.invalid }" required />
                  <div *ngIf="f.submitted && laserId.invalid" class="invalid-feedback">
                    <div *ngIf="laserId.errors.required">กรุณาระบุ Laser Code</div>
                  </div>
                </div>
              </div>

                
              <div class="form-group row">
                <label for="signature" class="col-lg-4 col-form-label">ชื่อผู้ตรวจสอบ</label>
                <div class="col-lg-8">
                  <input type="text" class="form-control" name="signature" [(ngModel)]="obj.signature" #signature="ngModel" [ngClass]="{ 'is-invalid': f.submitted && signature.invalid }" required />
                  <div *ngIf="f.submitted && signature.invalid" class="invalid-feedback">
                    <div *ngIf="signature.errors.required">กรุณาระบุชื่อผู้ตรวจสอบ</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="ibox-footer" >
            <div class="row">
              <div class="col-lg-12 text-right">
                  <div class="clearfix"></div>
                  <button class="btn btn-primary btn-block" type="submit">ตรวจสอบสถานะ</button>              
                </div>
              </div>
          </div>
      </div>
    </form>
      </div>
      
    </div>
  </div>
