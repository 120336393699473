import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';
 
declare var jQuery:any;

@Component({
  selector: 'top-bar',
  templateUrl: 'top-bar.template.html',
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class TopBarComponent {

  constructor(
    private authService: AuthService,
    private router :Router
  ) {}

  public ngOnInit():any {
    //detectBody();
  }

   toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    //smoothlyMenu();
  }

  public onResize(){
    //detectBody();
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
