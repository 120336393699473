import { Component, OnInit,ViewContainerRef } from '@angular/core';
import { DataService } from '../../shared/services/dataService';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'investor-form',
    templateUrl: './investor-form.component.html'
})

export class InvestorFormComponent  implements OnInit{
 
    submitted = false;
    routeParams = null;
    obj: any = {id:0};
    data:any = {
      titleList:[],
      personTypeList:[],
      provinceList:[],
      districtList:[],
      subDistrictList:[]
    }
    permission: any = {};
    personalList:any=[];
    id = 0;
    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private service: DataService,
        private authService: AuthService
    ) {
       
    }
 
    ngOnInit() {
      this.routeParams = this.activeRoute.snapshot.params;
      if(this.routeParams.id != null) this.getRow(this.routeParams.id) ;
      //this.permission = await this.authService.getPermission("investor");
      this.permission =   this.authService.getPermission(this.router.url);
      this.getData()
    }
 
    getData() {
      this.service.get("investorcontact/getdata")
        .subscribe((response: any) => {
          this.data = response;
          //this.obj.position = response.position;
        },  error => {
            this.toastr.error(error.error.message, 'Error!');
       });
    }

    getRow(id: any) {
      this.service.get("investorcontact/get/"+id)
        .subscribe((response:any) => {
          this.obj = response.data;
          this.data.districtList=response.districts;
          this.data.subDistrictList=response.subdistricts;
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
   
    changeProvince(e) {
      //console.log(e)
      this.service.get("province/getdistrict/"+e)
      .subscribe((response: any) => {
            this.data.districtList = response;       
          },error => {
            this.toastr.error(error.error.message, 'Error!');
      });
    }
    changeDistrict(e) {
      this.service.get("province/getsubdistrict/"+e)
      .subscribe((response: any) => {
            this.data.subDistrictList = response;       
          },error => {
            this.toastr.error(error.error.message, 'Error!');
      });
    }
    changeSubDistrict(e) {
      //console.log(e)
      var obj=this.data.subDistrictList.find((o:any)=>o.id==e)
      console.log(obj)
      this.obj.zipCode=obj.zipcode;
    }

    
    onSubmit() {
 
      this.service.post("investorcontact/post", this.obj)
        .subscribe((response: any) => {
          //this.obj = response;
          this.toastr.success("Create or Update Completed.", 'Succcess');
          this.router.navigate(['/setup/investor/editing/'+response.data.id]);
        },
        error => {
          this.toastr.error(error.error.message, 'Error!');
        });
    
    }
}
