import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

@Injectable()
export class AlertService {
  info(message: string): Promise<SweetAlertResult> {
    return this.showAlert('info', 'Information', message);
  }

  success(message: string): Promise<SweetAlertResult> {
    return this.showAlert('success', 'Success', message);
  }

  warning(message: string): Promise<SweetAlertResult> {
    return this.showAlert('warning', 'Warning', message);
  }

  error(message: string): Promise<SweetAlertResult> {
    return this.showAlert('error', 'Error', message);
  }

  private showAlert(type: SweetAlertIcon, title: string, text: string): Promise<SweetAlertResult> {
    const options: SweetAlertOptions = {
      title,
      text,
      icon: type,
      cancelButtonColor: "#1ab394",
      confirmButtonText: 'OK',
    };

    return Swal.fire(options);
  }
}
